<template lang="pug">
  .constraints
    .title-description
      .d-flex.align-center
        .filter-title.nio-h4.text-primary-darker Constraints
        NioTooltip.test(
          open-on-hover
          message="SQL queries are case-sensity. Please default to using lowercase letters."
        )
      .description.nio-p.text-primary-dark Enter one or more Spark SQL queries below. Each query may target one filterable attribute. Include only the part of the query after the <strong>WHERE</strong> keyword and do not include a semicolon at the end of the query. 
    .filter-value 
      .spark-query(
        v-for="(query, index) in spark.queries"
        :key="query.id"
      )
        NioCombobox(
          v-model="spark.queries[index].query"
          :items="computeFilterableFields(index)"
          placeholder="Spark SQL Query"
        )
        button.remove-spark-query(
          @click="removeQuery(index)"
        )
          NioIcon(
            :color="getThemeColor('primaryDark')" 
            :size="14"
            name="utility-minus" 
          )
      NioButton.add-spark-query(
        normal-secondary
        @click="addQuery"
      ) Add Spark SQL
  
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import { makeDataRules } from '../../createSubscription' 
import { makeRandomId } from '@narrative.io/tackle-box/src/modules/helpers'

export default { 
  props: {
    "spark": { type: Object, required: true },
    "filterableFields": { type: Array, required: false },
    "dataRulesParams": { type: Object, required: false }
  },
  data: () => ({
    sparkLastValidated: null,
    cancelCheckSparkQueries: false
  }),
  watch: {
    spark: {
      deep: true,
      handler(val) {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.checkSparkQueries()
        }, 200)
      }
    }
  },
  methods: {
    addQuery() {
      this.spark.queries.push({
        id: makeRandomId(),
        query: []
      })
    },
    removeQuery(index) {
      this.spark.queries.splice(index,1)
    },
    getThemeColor(colorName) {
      return getThemeColor(colorName)
    },
    computeFilterableFields(index) {
      const query = this.spark.queries[index]
      const tagElement = query.query.find(element => element.value)
      if (tagElement) {
        return [this.filterableFields.find(field => field.value === tagElement.value)]
      } else {
        return this.filterableFields
      }
    },
    async checkSparkQueries() {
      if (JSON.stringify(this.spark) !== JSON.stringify(this.sparkLastValidated)) {
        this.$emit('disableFiltersForSparkQueries')
        this.setCheckingSparkQueries(true)
        if (!this.spark.queries.find(query => query.query.length > 1 || (query.query[0] && query.query[0] !== ''))) {
          this.$emit('setSparkQueriesValid', true)
          this.setCheckingSparkQueries(false)
          if (this.checkingSparkQueries) {
            this.cancelCheckSparkQueries = true
          }
        } else if (this.spark.queries.find(query => !this.isEmptySparkQuery(query.query) && !query.query.find(queryElement => queryElement.value))) {
          this.$emit('setSparkQueriesValid', false)
           this.setCheckingSparkQueries(false)
          if (this.checkingSparkQueries) {
            this.cancelCheckSparkQueries = true
          }
        } else {
          const params = {
            details: {
              type: 'marketplace',
              data_rules: makeDataRules(
                this.dataRulesParams.filterable,
                this.dataRulesParams.deliverable,
                this.dataRulesParams.filters,
                this.dataRulesParams.attributes,
                this.dataRulesParams.deduplication,
                this.dataRulesParams.frequency,
                this.dataRulesParams.ingestionTimestamp,
                this.spark
              ),
              pricing: {
                micro_cents_usd: 100000
              }
            } 
          }
          this.$nioOpenApi.post(`/data-shops/subscriptions/matches`, JSON.stringify(params)).then(resp => {
            this.setCheckingSparkQueries(false)
            if (resp.status === 200) {
              if (!this.cancelCheckSparkQueries) {
                this.$emit('setSparkQueriesValid', true)
              } 
              this.cancelCheckSparkQueries = false
            } else {
              if (!this.cancelCheckSparkQueries) {
               this.$emit('setSparkQueriesValid', false)
              } 
              this.cancelCheckSparkQueries = false
            }
          }, err => {
             this.setCheckingSparkQueries(false)
            if (!this.cancelCheckSparkQueries) {
              this.$emit('setSparkQueriesValid', false)
            } 
            this.cancelCheckSparkQueries = false
          })  
        } 
        this.sparkLastValidated = JSON.parse(JSON.stringify(this.spark))
      }
    },
    setCheckingSparkQueries(val) {
      if (val) {
        this.checkingSparkQueries = true
        this.$emit('checkingSparkQueries')
      } else {
        this.checkingSparkQueries = false
        this.$emit('checkingSparkQueriesComplete')
      }
    },
    isEmptySparkQuery(query) {
      return !query.find(element => element !== '')
    },
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.constraints
  width: 100%
  +nio-filter-header
  flex-direction: column
  border-top: 0.0625rem solid $c-primary-lighter
  .filter-value
    width: 100%
    .add-spark-query
      align-self: flex-end
    & > .spark-query + .spark-query
      margin-top: -0.5rem
    .spark-query
      width: 100%
      display: flex
      justify-content: space-around
      & > * + *
        margin-left: 0.5rem
      .nio-combobox
        flex-grow: 2
        min-width: 50rem
      .nio-text-field
        flex-grow: 2
        ::v-deep .v-input__slot
          border-width: 0.0625rem !important
      .remove-spark-query
        width: 100%
        height: 3.375rem
        width: 3.375rem
        flex-shrink: 0
        flex-grow: 0
        border: 0.0625rem solid $c-primary-lighter
        border-radius: 0.5rem
        &:hover
          cursor: pointer
          background-color: $c-canvas 
  
</style>
