let deepCopy = (val) => JSON.parse(JSON.stringify(val))

let makeDataRules = (filterable, deliverable, filters, attributes, deduplication, frequency, ingestionTimestamp, spark) => {
  const uniqueAttributeNames = []
  filterable.forEach(filterableProperty => {
    if (!uniqueAttributeNames.includes(filterableProperty[0].name)) {
      uniqueAttributeNames.push(filterableProperty[0].name)
    }
  })
  deliverable.forEach(deliverableProperty => {
    if (!uniqueAttributeNames.includes(deliverableProperty[0].name)) {
      uniqueAttributeNames.push(deliverableProperty[0].name)
    }
  })
  
  let dataRulesObj = {
    attributes: []
  }
  const joinFilter = filters?.find(filter => filter.value === 'join')
  const datasetFilter = makeDatasetFilter(joinFilter)
  if (datasetFilter) {
    dataRulesObj.dataset_filter = datasetFilter
  }

  let deduplicationObj = deduplication.value === 'custom' ? {
    period: `P${deduplication.customOption.value[0]}${deduplication.customOption.value[1]}`,
    attribute_references: []
  } : null

  uniqueAttributeNames.forEach(attributeName => {
    const targetAttribute = attributes.find(attribute => attribute.name === attributeName)
    
    let attributeObj = {
      attribute_id: targetAttribute.id,
      fields: [],
      optional: null
    }
    
    const allPaths = [...filterable, ...deliverable].filter(path => path[0].name === attributeName && (path.length > 1 || targetAttribute.type !== 'object')).filter(path => !path.includes('items'))
    
    const deliverablePathStrings = deliverable.filter(path => path[0].name === attributeName).map(path => {
      const pathCopy = deepCopy(path)
      pathCopy.shift()
      pathCopy.unshift(path[0].name)
      return pathCopy.join('.')
    }).filter(pathString => pathString.length > 1 || targetAttribute.type !== 'object')
    
    const filterablePaths = filterable.filter(path => path[0].name === attributeName && (path.length > 1 || targetAttribute.type !== 'object'))

    allPaths.forEach(path => {
      const pathCopy = deepCopy(path)
      pathCopy.shift()
      pathCopy.unshift(path[0].name)
      const pathString = pathCopy.join('.')
      if (!attributeObj.fields.find(field => field.field === pathString)) {
        attributeObj.fields.push({
          field: pathString,
          exported: deliverablePathStrings.includes(pathString)
        })
      }
    })

    filterablePaths.forEach(path => {
      const filter = findFilterByPath(filters, path) 
      if (deduplication.value === 'custom') {
        if (!deduplicationObj.attribute_references.find(attributeRef => attributeRef.attribute_id === path[0].id)) {
          deduplicationObj.attribute_references.push({
            attribute_id: path[0].id,
            column_names: []
          })
        }
      }
      if (filter && !filter.path.includes('items')) {
        const pathCopy = deepCopy(path)
        pathCopy.shift()
        pathCopy.unshift(path[0].name)
        const pathString = pathCopy.join('.')
        attributeObj.fields.find(field => field.field === pathString).filter = makeFilter(filter, spark)
        if (deduplication.value === 'custom') {
          const dedupPathStrings = deduplication?.customOption?.supportingOption?.value?.map(dedupPath => {
            const dedupPathCopy = deepCopy(dedupPath)
            dedupPathCopy.shift()
            dedupPathCopy.unshift(dedupPath[0].name)
            return dedupPathCopy.join('.')
          })
          if (dedupPathStrings?.includes(pathString)) {
            deduplicationObj.attribute_references.find(attributeRef => attributeRef.attribute_id === path[0].id).column_names.push(pathString)
          }
        }
      }
    })
    attributeObj.optional = uniqueAttributeNames.length === 1 ? false : computeOptional(attributeObj)
    dataRulesObj.attributes.push(attributeObj)
  })
  
  dataRulesObj.attributes.map(attribute => { // set optional to false if it's a dataset join
    if (datasetFilter && ((datasetFilter.attribute && datasetFilter.attribute.attribute_id === attribute.attribute_id) || (datasetFilter.parent && datasetFilter.parent.attribute_id === attribute.attribute_id))) {
      attribute.optional = false
    }
  })
  if (!dataRulesObj.attributes.find(attribute => !attribute.optional)) {
    dataRulesObj.attributes[0].optional = false
  }

  if (deduplicationObj) {
    deduplicationObj.attribute_references = deduplicationObj.attribute_references.filter(attributeRef => attributeRef.column_names.length > 0)
    dataRulesObj.deduplication = deduplicationObj
  }
  if (frequency && frequency[0] && frequency[0].value !== 'default') {
    const attributePaths = frequency[0].customOption.value.dataPoints
    const attributeReferences = attributePaths && attributePaths.length > 0 ? attributePaths.map(path => {
      return path.reduce((acc, element) => {
        if (element.name) {
          return acc += element.name
        } else {
          return acc += `.${element}`
        }
      }, '')
    }) : []
    if (attributeReferences.length > 0) {
      let frequencyFilter = {
        attribute_references: makeAttributeReferences(attributeReferences, attributes),
        min_inclusive: parseInt(frequency[0].customOption.value.minOccurences),
        max_inclusive: parseInt(frequency[0].customOption.value.maxOccurences)
      }
      dataRulesObj.frequency_filter = frequencyFilter
    }
  }
  if (ingestionTimestamp && ingestionTimestamp[0] && ingestionTimestamp[0].value !== 'default') {
    const itFilter = ingestionTimestamp[0]
    dataRulesObj.ingestion_timestamp_filter = {
      recency: itFilter.customOption.value.recency.enabled ? `P${itFilter.customOption.value.recency.value}${itFilter.customOption.value.recency.period}` : null,
      from : itFilter.customOption.value.start.enabled ? {
        type : "inclusive",
        value : itFilter.customOption.value.start.timestamp
      } : null,
      to : itFilter.customOption.value.end.enabled ? {
        type : "inclusive",
        value : itFilter.customOption.value.end.timestamp
      } : null
    }
  }

  

  return dataRulesObj
}

function findSparkQueriesForFilter(filter, spark) {
  return spark.queries.reduce((acc, query) => {
    const targetPath = query.query.find(element => element.value)?.value
    const stringPath = targetPath && targetPath.length > 0 ? targetPath.reduce((acc, element) => {
      if (element.name) {
        return acc += element.name
      } else {
        return acc += `.${element}`
      }
    }, '') : null
    const queryPathElements = stringPath ? stringPath.split('.') : null
    if (!queryPathElements || filter.path.length !== queryPathElements.length) {
      return acc
    }
    const equivalentPathElements = filter.path.filter((filterPathElement, index) => {
      if (index === 0 && filterPathElement.name === queryPathElements[0]) {
        return true
      } else {
        if (queryPathElements[index] === filterPathElement) {
          return true
        }
      }
      return false
    })
    if (equivalentPathElements.length === filter.path.length) {
      let result = ''
      query.query.forEach(queryElement => {
        if (queryElement.value) {
          result += queryElement.value.reduce((acc, element) => {
            if (element.name) {
              return acc += element.name
            } else {
              return acc += `.${element}`
            }
          }, '')
        } else {
          result += queryElement
        }
      })
      return [...acc, result]
    }
    return acc
  }, [])
}

function makeFilter(filter, spark) {
  if (!filter) {
    return null
  }
  const sparkQueries = findSparkQueriesForFilter(filter, spark)
  if (sparkQueries.length > 0) {
    return {
      expressions: sparkQueries
    }
  } else {
    if (filter.value === 'default') {
      return null
    } else if (filter.value === 'ifPresent') {
      return "include_only_if_not_null_filter"
    } else {
      switch (filter.type) {
        case 'stringLimited':
          return filter.customOption.value.items.length > 0 ? {
            type : "include",
            list : filter.customOption.value.items
          } : null
        case 'stringMany':
          const items = filter.customOption.value.manualEntry.split("\n").map(val => val.trim())
          return filter.customOption.value.manualEntry && items.length > 0 ? {
            type : filter.customOption.value.listType,
            list : items
          } : null
        case 'number':
          return filter.customOption.value[0] || filter.customOption.value[1] ? {
            min : filter.customOption.value[0] ? {
              type : "inclusive",
              value : String(filter.customOption.value[0])
            } : null,
            max : filter.customOption.value[1] ? {
              type : "inclusive",
              value : String(filter.customOption.value[1])
            } : null
          } : null
        case 'simpleTimestamp':
          return filter.customOption.value.start.enabled || filter.customOption.value.end.enabled || filter.customOption.value.recency ? {
            recency: filter.customOption.value.recency.enabled ? `P${filter.customOption.value.recency.value}${filter.customOption.value.recency.period}` : null,
            from : filter.customOption.value.start.enabled ? {
              type : "inclusive",
              value : filter.customOption.value.start.timestamp
            } : null,
            to : filter.customOption.value.end.enabled ? {
              type : "inclusive",
              value : filter.customOption.value.end.timestamp
            } : null
          }	: null
        case 'boolean':
          return filter.customOption.value === true || filter.customOption.value === false ? {
            type: "include",
            list: [String(filter.customOption.value)]
          } : null
        default:
          return null
      }
    }
  }
}


function makeAttributeReferences(dataPoints, attributes) {
  const attributeReferences = []
  if (dataPoints) {
    const splitDataPoints = dataPoints.map(dataPoint => {
      return dataPoint.split('.')
    })
    const uniqueAttributes = splitDataPoints.reduce((acc, dataPoint) => {
      const attributeName = dataPoint[0]
      const matchingEntry = acc.find(entry => entry.attributeName === attributeName)
      if (matchingEntry) {
        matchingEntry.dataPoints.push(dataPoint)
        return acc
      } else {
        return [...acc, { attributeName: attributeName, dataPoints: [dataPoint]}]
      }
    }, [])
  
    uniqueAttributes.forEach(uniqueAttribute => {
      let attributeReference = {
        attribute_id: attributes.find(attribute => attribute.name === uniqueAttribute.attributeName).id,
        column_names: uniqueAttribute.dataPoints.map(dataPoint => {
          return dataPoint.join('.')
        })
      }
      attributeReferences.push(attributeReference)
    })
  }
  return attributeReferences
}

// TODO update this to allow mutliple dataest filters when supported in backend
function makeDatasetFilter(filter) {
  if (filter) {
    const datasetFilter = {
      dataset_id: filter.joinOption.value.selectedDataset,
      attribute: { attribute_id: filter.joinOption.config.targetAttribute, field: filter.joinOption.config.field},
      inclusion: filter.joinOption.value.joinType
    }
    if (filter.joinOption.value.geometryType) {
      datasetFilter.type = 'spatial'
      datasetFilter.predicate = filter.joinOption.value.geometryType === 'Intersects' ? 'st_intersects' : 'st_contains'
    } 

    if (!filter.joinOption.value.geometryType && filter.joinOption.parentAttribute) {
      datasetFilter.parent = {
        attribute_id: filter.joinOption.parentAttribute.attributeId,
        field: filter.joinOption.parentAttribute.path
      }
    }
    return datasetFilter
  }
  return undefined
}

function findFilterByPath(filters, path) {
  const filtersCopy = deepCopy(filters).map(filter => {
    if (!filter.tmpPath) {
      filter.tmpPath = deepCopy(filter.path)
    }
    return filter
  })
  const pathCopy = deepCopy(path)
  if (pathCopy.length === 0) {
    if (filters.length > 0 && filters[0]) {
      filters[0].tmpPath = undefined
    }
    return filters[0]
  } else {
    const matchingFilters = filtersCopy.filter(filter => {
      return filter && filter.tmpPath[0] && (filter.tmpPath[0] === pathCopy[0] || (filter.tmpPath[0].name && pathCopy[0].name && filter.tmpPath[0].name === pathCopy[0].name))
    })
    matchingFilters.map(filter => {
      filter.tmpPath.shift()
    })
    pathCopy.shift()
    return findFilterByPath(matchingFilters, pathCopy)
  }
}

function computeOptional(attributeObj) {
  return attributeObj.fields.find(field => field.filter !== null) ? false : true
}

export {
  makeDataRules
}