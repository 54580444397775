import { DefaultOption, IncludeIfPresentOption, CustomOption } from '../../options'

function makeStringLimitedFilter(attribute) {
  return {
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "stringLimited",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption,
      CustomOption
    ],
    customOption: {
      config: {
        searchable: true,
        multiple: true,
        items: attribute.enum.sort().map(item => { 
          return  {
            value: item,
            label: item
          }
        })
      },
      value: {
        items: []
      }	
    }
  }
}

export default makeStringLimitedFilter