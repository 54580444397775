import { makeFilterableFieldsItems } from '../../helpers'
import { areSamePaths } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

let makeFrequencyFilter = (filterObj, filters) => {
  const filterCopy = filterObj
  filterObj.customOption.config.dataPointsOptions = makeFilterableFieldsItems(filters)
  return filterCopy
}

let frequencyFilterValid = (filter) => {
  if (filter.value !== 'default') {
    const frequency = filter
    const min = frequency.customOption.value.minOccurences
    const max = frequency.customOption.value.maxOccurences
    const dataPoints = frequency.customOption.value.dataPoints
    const period = frequency.customOption.value.period[0]

    if (
      !validateFrequencyFilterMin(min, frequency).length &&
      !validateFrequencyFilterMax(max, frequency).length &&
      !validateFrequencyFilterPeriod(period).length &&
      dataPoints?.length > 0
    ) {
      return true
    } else {
      return false
    }
  }
  return true
}

let validateFrequencyFilterMin = (value, frequencyFilter) => {
  if (!value || value.trim().length === 0) {
    return 'required'
  } else if (frequencyFilter.customOption.value.maxOccurences && parseInt(value) > parseInt(frequencyFilter.customOption.value.maxOccurences)) {
    return 'must be less than max occurences'
  } else if (!Number.isInteger(Number(value))) {
    return 'must be an integer'
  } else if (parseInt(value) < 1) {
    return 'must be greater than or equal to 1'
  } else {
    return true
  }
}

let validateFrequencyFilterMax = (value, frequencyFilter) => {
  if (!value || value.trim().length === 0) {
    return 'required'
  } else if (frequencyFilter.customOption.value.minOccurences && parseInt(value) < parseInt(frequencyFilter.customOption.value.minOccurences)) {
    return 'must be greater than min occurences'
  } else if (!Number.isInteger(Number(value))) {
    return 'must be an integer'
  } else if (parseInt(value) < 1) {
    return 'must be greater than or equal to 1'   
  } else {
    return true
  }
}

let validateFrequencyFilterPeriod = (value) => {
  if (!value || value.trim().length === 0) {
    return 'required'
  } else if (!Number.isInteger(Number(value))) {
    return 'must be an integer'
  } else if (parseInt(value) < 1) {
    return 'must be greater than or equal to 1'   
  } else {
    return true
  }
}

const DefaultFrequencyFilter = {
  name: "frequency",
  type: "custom",
  title: "Frequency",
  description: "Indicate the minimum and maximum number of times a data point must be seen in order for it to be included in your order",
  value: 'default',
  options: [
      {
      label: `Include all data points`,
      value: 'default',
    },
    {
      label: "Custom",
      value: 'custom',
    }
  ],
  customOption: {
    heading: 'Deduplication Strategy',
    description: 'Exclude irrelevant or redundant data even when you buy from multiple suppliers.',
    config: {
      dataPointsOptions: [],
      periodOptions: [
        {
          label: 'Day(s)',
          value: 'D'
        },
        {
          label: 'Week(s)',
          value: 'W'
        },
        {
          label: 'Month(s)',
          value: 'M'
        }
      ]
    },
    value: {
      minOccurences: '2',
      maxOccurences: '10',
      dataPoints: null,
      period: ['30', 'D']
    }
  }
}

let applyPreviousFrequencyState = (prevFilter, filters) => {
  let newFilter = makeFrequencyFilter(JSON.parse(JSON.stringify(DefaultFrequencyFilter)), filters)
  if (prevFilter) {
    let newCustomDataPoints = prevFilter.customOption?.value?.dataPoints?.filter(
      newPath => newFilter.customOption?.config?.dataPointsOptions?.find(
        availablePath => areSamePaths(availablePath.value, newPath)
      ) !== undefined)
    newFilter.value = prevFilter.value
    newFilter.customOption.value = {
      ...prevFilter.customOption.value,
      dataPoints: newCustomDataPoints?.length ? newCustomDataPoints : [],
    } 
  }
  return [newFilter]
}

export {
  DefaultFrequencyFilter,
  makeFrequencyFilter,
  frequencyFilterValid,
  validateFrequencyFilterMin,
  validateFrequencyFilterMax,
  validateFrequencyFilterPeriod,
  applyPreviousFrequencyState
}