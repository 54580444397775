import { DefaultOption, IncludeIfPresentOption, CustomOption } from '../../options'

function makeNumericFilter(attribute) {
  return { 
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "number",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption,
      CustomOption
    ],
    customOption: {
      config: {
        unconstrained: true
      },
      value: [null, null]
    }
  }
}

export default makeNumericFilter