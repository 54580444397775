<template lang="pug">
.budget-step
  .header.step-header
    h2.nio-h2.text-primary-darker Set your budget, delivery cadence, and payment method
  .budget-group
    .title-description
      .filter-title.nio-h3.text-primary-dark Budget Calculator 
      .description.nio-p.text-primary-dark Enter your data spend budget
    .budget-cadence.filter
      .title-description
        .filter-title.nio-h4.text-primary-dark Your Budget
          NioTooltip(
            message="Define how much you want to spend on your subscription and the cadence in which your budget resets."
          )    

      .budget-input.filter-value
        .cadence-input
          NioTextField(
            v-model="customBudget"
            type="number"
            placeholder="0.00"
            icon-name="utility-dollar-sign"
            icon-color="#415298"
            prepend
            currency
            solo
          )
          NioSelect(
            v-if="budgetCadenceOptions"
            v-model="selectedBudgetCadence"
            :items="budgetCadenceOptions"
            solo
            item-text="label"
            item-value="value"
          )
          .invalid-error(v-if="budgetMaxCadenceError")
            .nio-p.text-error Budget cadence cannot be a longer period than delivery cadence
        NioButton(
          :variant="'tertiary'"
          :disabled="!hasValidForecastResults"
          size="small"
          @click="maxBudget"
        ) Buy all matching rows
    .delivery-cadence.filter
      .title-description
        .filter-title.nio-h4.text-primary-dark Delivery Cadence
        .description.nio-p.text-primary-dark Define how often you want data to be delivered. The delivery cadence must be less than or equal to your budget cadence.
      .filter-value
        .nio-p.text-primary-dark Deliver data once every
        NioSelect(
          v-if="deliveryCadenceOptions"
          v-model="selectedDeliveryCadence"
          :items="computedDeliveryCadenceOptions"
          solo
        )
    NioDivider(horizontal-solo)
    .total-subscription-cost.filter
      .title-description
        .filter-title.nio-h3.text-primary-dark Total subscription cost
      .estimate-wrapper 
        .cost-estimate
          .nio-p.text-primary-dark(v-if="forecastResults === 'loading' || costForecastResults === 'loading'") Please wait...
            NioTooltip.test(
              message="A forecast must be available to view this information. Please check back once your forecast is complete."
            )
          v-progress-circular.progress(
            v-else-if="forecastIsStale || costForecastIsStale"
            size="80" 
            color="#1438F5"
            indeterminate 
          )
          .nio-p.text-primary-darker(v-else-if="customBudget === null || customBudget === undefined || customBudget === ''") Enter a budget to view the subscription costs
          .nio-p.text-primary-dark(v-else-if="customBudget != null && !formattedDeliverableRows") No available data
          .total-subscription(v-else-if="forecastedDataCount !== false && forecastIsStale === false && costForecastIsStale === false") 
            .nio-p-small.text-primary-dark With a {{ formattedBudgetCadence }} budget of
            .total-subscription-budget.nio-h2.text-primary-dark {{ formattedBudget }}
            .nio-p-small.text-primary-dark You can get 
            .total-rows-delivered.nio-h2.text-primary-dark {{ forecastedDataCount }} rows delivered *
            .nio-p-small.text-primary-dark Out of {{ formattedDeliverableRows }} rows available.
          .nio-p.text-primary-dark(v-else) Not available
            NioTooltip.test(
              message="Either your budget is too low or your forecast returned no rows that match your criteria. Try entering a budget of at least $100 or broaden your criteria and run your forecast again to get an updated value."
            )
      .nio-p.text-primary-dark(v-if="forecastedDataCount != null") * Processing costs and rows delivered may vary depending on the data available at the time of purchase
    NioDivider(horizontal-solo)
    .cost-details(v-if="forecastedDataCount != null && forecastedDataSize != null && processingRate != null && forecastedDataCount !== false && forecastedDataSize !== false && processingRate !== false && !forecastIsStale && !costForecastIsStale")
      .filter-title.nio-h3.text-primary-dark Estimated cost details 
      .data-processing-fees
        .cost-details-heading.filter-title.nio-h4.text-primary-dark Data processing fees 
        .cost-details-heading.nio-p.text-primary-dark The estimated cost to process the data that matches your query, based on the bytes of data scanned and your platform processing rate. Subsequent deliveries may incur additional fees.
        NioDivider(horizontal-solo)
        .estimated-data.filter
          .title-description
            .nio-p.bold.text-primary-dark Estimated data to scan
          .data-size.filter-value 
            .nio-p.bold.text-primary-dark {{ forecastedDataSize }}
        NioDivider(horizontal-solo)
        .processing-rate.filter
          .title-description
            .nio-p.bold.text-primary-dark x Platform processing rate
          .unit-rate.filter-value 
            .nio-p.bold.text-primary-dark ${{ processingRate.toFixed(2) }} per GB
        NioDivider(horizontal-solo)
        .processing-fee.filter
          .title-description
            .nio-p.bold.text-primary-dark = ESTIMATED data processing fee
          .total-processing-cost.filter-value 
            .nio-p.bold.text-primary-dark {{ estimatedProcessingFee }}
        NioDivider(horizontal-solo)
      .deliverable-data 
        .cost-details-heading.filter-title.nio-h4.text-primary-dark Cost of deliverable data 
        .cost-details-heading.nio-p.text-primary-dark The actual cost of data (rows) set by the seller plus data processing fee and marketplace fee.
        NioDivider(horizontal-solo)
        .delivered-rows.filter
          .title-description
            .nio-p.bold.text-primary-dark {{ forecastedDataCount }} rows delivered
            .nio-p.text-primary-dark Out of {{ formattedDeliverableRows }} rows available
          .delivered-cost.filter-value 
            .nio-p.bold.text-primary-dark {{ estimatedDataCost }}
        NioDivider(horizontal-solo)
        .marketplace-fee.filter 
          .title-description
            .nio-p.bold.text-primary-dark + Marketplace fee
            .marketplace-fee-details.nio-p.text-primary-dark {{ ((marketplaceFactor - 1) * 100).toFixed(3) }}% of deliverable data cost
          .marketplace-fee-value.filter-value 
            .nio-p.bold.text-primary-dark {{ estimatedMarketplaceFee }}
        NioDivider(horizontal-solo)
        .processing-fee.filter 
          .title-description
            .nio-p.bold.text-primary-dark + Data processing fee
          .processing-cost.filter-value 
            .nio-p.bold.text-primary-dark {{ estimatedProcessingFee }}
        NioDivider(horizontal-solo)
        .total-deliverable.filter 
          .title-description
            .nio-h3.bold.text-primary-darker Estimated cost of subscription
          .total-cost.filter-value 
            .nio-h3.bold.text-primary-darker {{ estimatedTotalCost }}

</template>

<script>

import { formatCurrency, formatCurrencyNoCents,formatNumberVerbose, isValidPositiveNumber, readableSize } from '@/modules/helpers'

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "processingRate": { type: Number, required: false, default: 5 },
    "forecastResults": { type: Object | String, required: false, default: 'loading' },
    "costForecastResults": { type: Object | String, required: false, default: 'loading' },
    "forecastIsStale": { type: Boolean, required: false },
    "costForecastIsStale": { type: Boolean, required: false },
    "marketplaceFactor": { type: Number, required: false, default: 1.25 }
  },
  data: () => ({
    customBudget: null,
    warning: {
      message: 'You are using a FREE version of the Universal Onboarding app. This limits the deliverable IDs you’ll receive to only 500,000  IDs every month. Upgrade your app subscription to remove this restriction',
      linkText: 'More Info',
      linkHref: ''
    },
    selectedBudgetCadence: 'Month',
    budgetCadenceOptions: [{
      label: 'Every Month', 
      value: 'Month'
    },
    {
      label: 'Every Day', 
      value: 'Day'
    }],
    selectedDeliveryCadence: 'Month',
    deliveryCadenceOptions: null,
    disabledDeliveryCadenceOptions: [],
    periodOptions: ['Month', 'Week', 'Day', 'Hour', 'One Time'],
    budgetMaxCadenceError: false
  }),	
  computed: {
    hasValidForecastResults() {
      const d = this.forecastResults
      const c = this.costForecastResults
      return !this.forecastIsStale && !this.costForecastIsStale && isValidPositiveNumber(d?.result?.rows) && isValidPositiveNumber(d?.result?.cost) && isValidPositiveNumber(c?.result?.cost?.rows) && isValidPositiveNumber(c?.result?.cost?.size)
    },
    cadenceMessage() {
      switch (this.selectedCadence) {
        case 'Week': 
          return 'You will receive data today and on every subsequent Sunday'
        case 'Month':
          return 'You will receive data today and then on the first of every month'
        default:
          return null
      }
    },
    forecastedDataCount() {
      const r = this.forecastResults
      const c = this.costForecastResults
      if (isValidPositiveNumber(this.customBudget) && isValidPositiveNumber(r?.result?.rows) && isValidPositiveNumber(r?.result?.cost) && isValidPositiveNumber(c?.result?.cost?.rows) && isValidPositiveNumber(c?.result?.cost?.size)) {
        // Budget should only be "purchasing" data using the remaining budget after marketplace fee has been covered
        const dataSpendBudget = this.customBudget / this.marketplaceFactor
        if (r?.result?.cost === 0) {
          return formatNumberVerbose(r.result.rows)
        }
        return formatNumberVerbose(
          Math.min(
            (dataSpendBudget  * 100000000) / (r.result.cost / r.result.rows), 
            r.result.rows
          )
        )
      } else return false
    },
    forecastedDataSize() {
      const r = this.costForecastResults
      if (isValidPositiveNumber(this.customBudget) && isValidPositiveNumber(r?.result?.cost?.rows) && isValidPositiveNumber(r?.result?.cost?.size)) {
          return readableSize(r.result.cost.size) 
      } 
      else return false
    },
    estimatedDataCost() {
      const r = this.forecastResults
      const c = this.costForecastResults     
      if (isValidPositiveNumber(this.customBudget)&& isValidPositiveNumber(r?.result?.rows) && isValidPositiveNumber(r?.result?.cost) && isValidPositiveNumber(c?.result?.cost?.rows) && isValidPositiveNumber(c?.result?.cost?.size)) {
        const dataSpendBudget = this.customBudget / this.marketplaceFactor
        const percentDataPurchased = Math.min((dataSpendBudget  * 100000000) / r.result.cost, 1)
        const estimatedCost =  r.result.cost * percentDataPurchased / 100000000
        return formatCurrency(estimatedCost)
      } else return false
    },
    estimatedMarketplaceFee() {
      const r = this.forecastResults
      if (isValidPositiveNumber(this.customBudget)&& isValidPositiveNumber(r?.result?.rows) && isValidPositiveNumber(r?.result?.cost)) {
        const dataSpendBudget = this.customBudget / this.marketplaceFactor
        const percentDataPurchased = Math.min((dataSpendBudget  * 100000000) / r.result.cost, 1)
        return formatCurrency(r.result.cost * percentDataPurchased * (this.marketplaceFactor - 1) / 100000000)
      } else return false
    },
    estimatedProcessingFee() {
      const r = this.costForecastResults
      if (isValidPositiveNumber(this.customBudget) && isValidPositiveNumber(r?.result?.cost?.rows) && isValidPositiveNumber(r?.result?.cost?.size)) {
          return formatCurrency(r.result.cost.size * this.processingRate / (1024 * 1024 * 1024))
      }
      else return false
    },
    estimatedTotalCost() {
      const r = this.forecastResults
      const c = this.costForecastResults
      if (this.formattedDeliverableRows != null) {
        const dataSpendBudget = this.customBudget / this.marketplaceFactor
        const percentDataPurchased = r?.result?.cost > 0 ? Math.min((dataSpendBudget * 100000000) / r.result?.cost, 1) : 0
        const estimatedDataCost = r?.result?.cost ? r.result.cost * percentDataPurchased / 100000000 : 0
        const transactionCost = estimatedDataCost * (this.marketplaceFactor - 1) 
        const processingFee = c?.result?.cost?.size ? c.result.cost.size * this.processingRate / (1024 * 1024 * 1024) : 0
        return formatCurrency(estimatedDataCost + processingFee + transactionCost)
      } else return false
    },
    computedDeliveryCadenceOptions() {
      return this.deliveryCadenceOptions.map(item => {
        return {
          text: item, 
          disabled: this.disabledDeliveryCadenceOptions.includes(item)
        }
      })
    },
    formattedBudget() {
      if (isValidPositiveNumber(this.customBudget)) {
        return formatCurrencyNoCents(this.customBudget)
      }
      else return false
    },
    formattedDeliverableRows() {
      const r = this.forecastResults
      if (isValidPositiveNumber(this.customBudget) && isValidPositiveNumber(r?.result?.rows)) {
          return formatNumberVerbose(r.result.rows)
      }
      else return false
    },
    formattedAvailableRows() {
      const r = this.costForecastResults
      if (isValidPositiveNumber(this.customBudget) && isValidPositiveNumber(r?.result?.cost?.rows) && isValidPositiveNumber(r?.result?.cost?.size)) {
          return formatNumberVerbose(r.result.cost.rows)
      }
      else return false
    },
    formattedBudgetCadence() {
      if (!this.selectedBudgetCadence) return false
      if (this.selectedBudgetCadence === 'Month') {
        return 'monthly'
      } else if (this.selectedBudgetCadence === 'Day') {
        return 'daily'
      } else return false
    }
  },
  watch: {
    selectedBudgetCadence(val) {
      const periodIndex = this.periodOptions.indexOf(val)
      const deliveryCadenceIndex = this.periodOptions.indexOf(this.selectedDeliveryCadence)
      const options = [...this.periodOptions]
      if (val === 'Day') {
        this.disabledDeliveryCadenceOptions = [...options.splice(0, periodIndex), 'One Time']
      } else {
        this.disabledDeliveryCadenceOptions = []
      }
      if (deliveryCadenceIndex < periodIndex) {
        this.selectedDeliveryCadence = this.periodOptions[periodIndex]
      } 
      this.updateModel()
      this.updateCostSummary()
    },
    selectedDeliveryCadence(val) {
      const periodIndex = this.periodOptions.indexOf(this.selectedBudgetCadence)
      const deliveryCadenceIndex = this.periodOptions.indexOf(val)
      if (deliveryCadenceIndex < periodIndex) {
        this.budgetMaxCadenceError = true
      }
      this.updateModel()
      this.updateCostSummary()
    },
    customBudget(val) {
      this.updateModel()
      this.updateCostSummary()
    },
    forecastedDataSize(val) {
      this.updateCostSummary()
    },
    formattedDeliverableRows(val) {
      this.updateCostSummary()
    },
    estimatedProcessingFee(val) {
      this.updateCostSummary()    
    },
    estimatedDataCost(val) {
      this.updateCostSummary()
    },
    estimatedMarketplaceFee(val) {
      this.updateCostSummary()
    },
    estimatedTotalCost(val) {
      this.updateCostSummary()
    }
  },
  mounted() {
    this.deliveryCadenceOptions = this.periodOptions
    this.customBudget = null
  },
  methods: {
    updateModel() {
      this.$emit('stepPayloadChanged', {
        budgetAmount: parseFloat(this.customBudget),
        budgetCadence: this.selectedBudgetCadence,
        deliveryCadence: this.selectedDeliveryCadence
      })
    },
    updateCostSummary() {
      this.$emit('costsEstimated', {
        budget: this.formattedBudget,
        dataCost: this.estimatedDataCost,
        estimatedDeliverableData: this.forecastedDataCount,
        transactionFee: this.estimatedMarketplaceFee,
        processingCost: this.estimatedProcessingFee,
        expectedTotal: this.estimatedTotalCost
      })
    },
    paymentMethodChanged(payload) {
      this.$emit('stepPayloadChanged', payload)
    },
    maxBudget() {
      this.customBudget = Math.round(this.forecastResults.result.cost * 1.25 / 100000000)
      return
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.budget-step
  .header
    margin-bottom: 1.5rem
    flex-direction: column
    align-items: center
    h2
      margin-bottom: 0.5rem
  .flex-1
    flex: 1
  .budget-group
    width: 100%
    border-radius: 0.75rem
    border: 0.0625rem solid var(--nio-theme-primary-lighter)
    overflow: hidden
    margin: 1rem 0 1.5rem 0
    padding: 1.5rem 1.5rem
    .filter
      +nio-filter-header
      .title-description
        padding-right: 48px
    & > .filter + .filter
      border-top: 1px solid $c-primary-lighter
    .budget-cadence, .delivery-cadence
      .filter-value
        flex-direction: row
        flex-wrap: nowrap
        align-items: flex-start
        .nio-text-field
          margin-right: 1rem
          width: 11.25rem
          margin-bottom: 0rem
          flex-grow: 0
          flex-shrink: 0
          ::v-deep .v-input__slot
            border-width: 0.0625rem
        .nio-select
          margin-bottom: 0px
          margin-top: 0px
          ::v-deep .v-select__selections
            padding-top: 0.65rem
    .budget-cadence
      .budget-input
        justify-content: space-between
        flex-direction: column
        .cadence-input
          display: flex
          flex-direction: row

    .delivery-cadence
      .filter-value
        .nio-p
          margin-right: 1rem
          padding-top: 1.05rem
        .nio-select
          width: 180px
    .total-subscription-cost
      display: flex
      flex-direction: column
      padding: 0rem 0rem 0rem 0rem
      .estimate-wrapper
        display: flex
        justify-content: center
        align-items: center
        .cost-estimate
          background: #f4f7fb
          border-radius: 0.8rem
          display: flex
          flex-direction: column
          justify-content: center
          align-items: center
          min-width: 66%
          padding: 1.5rem
          gap: 1rem
          margin: 1rem 0
          .total-subscription
            display: flex
            flex-direction: column
            align-items: center
            .total-subscription-budget
              margin: 0.375rem 0rem 1rem 0rem
            .total-rows-delivered
              margin: 1rem 0rem 0.375rem 0rem
    .cost-details  
      padding-bottom: 1rem   
      .cost-details-heading
        padding-left: 1rem 
      .data-processing-fees
        padding: 1rem 0rem 0rem 0rem
        .estimated-data
          padding: 0rem 2rem 0rem 2.75rem
          min-height: unset
          .data-size
            align-items: flex-end
        .processing-rate
          padding: 0rem 2rem
          min-height: unset
          .unit-rate
            align-items: flex-end
        .processing-fee
          padding: 0rem 2rem
          min-height: unset
          .total-processing-cost
            align-items: flex-end
      .deliverable-data
        padding: 1rem 0rem 0rem 0rem
        .delivered-rows
          padding: 0rem 2rem 0rem 2.75rem
          min-height: unset
          .delivered-cost
            align-items: flex-end
        .marketplace-fee
          padding: 0rem 2rem
          min-height: unset
          .title-description
            .marketplace-fee-details
              padding: 0rem 0rem 0rem 0.75rem
          .marketplace-fee-value
            align-items: flex-end 
        .processing-fee
          padding: 0rem 2rem
          min-height: unset
          .processing-cost
            align-items: flex-end 
        .total-deliverable
          padding: 0rem 2rem 0rem 0rem
          min-height: unset
          .total-cost
            align-items: flex-end
</style>
