import { toCamelCase } from '@/modules/helpers'
import { makePathString, getAttributeFromPath, getJoinOptionsByPath } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { makeDeduplicationFilter, applyPreviousDeduplicationState, updateDeduplication } from './filters/types/advanced/deduplication'
import { makeFrequencyFilter, applyPreviousFrequencyState } from './filters/types/advanced/frequency'
import { makeIngestionTimestampFilter, applyPreviousIngestionTimestampState } from './filters/types/advanced/ingestionTimestamp'
import { makeFilterableFieldsItems, getAllPathsSet, getPropertyKey } from './filters/helpers'
import { applyPreviousSparkState } from './filters/types/advanced/constraints'
import makeArrayFilter from './filters/types/core/array'
import makeBooleanFilter from './filters/types/core/boolean'
import makeBinaryFilter from './filters/types/core/binary'
import makeNumericFilter from './filters/types/core/numeric'
import makeStringLimitedFilter from './filters/types/core/stringLimited'
import makeStringSimpleFilter from './filters/types/core/stringSimple'
import makeTimestampFilter from './filters/types/core/timestamp'

function initFilters(filterable, attributes, datasets) {
  const filters = []
  filterable.forEach(path => {
    const [pathInfo, propertyName] = path
    const parentAttribute = attributes.find(attribute => pathInfo.id === attribute.id)
    const requiredAttributes = new Set(parentAttribute.required)
    const targetAttribute = {
      ...getAttributeFromPath([...path], parentAttribute),
      name: toCamelCase(makePathString(path).replaceAll('->', '')),
      path: [...path],
      id: parentAttribute.id,
      required: requiredAttributes.has(propertyName)
    }

    let filter
    if (!path.includes('items')) {
      switch (targetAttribute.type) {
        case 'array':
          filter = makeArrayFilter(targetAttribute, datasets)
          break;
        case 'string':
          if (targetAttribute.enum) {
            filter = makeStringLimitedFilter(targetAttribute, datasets)
          } else {
            filter = makeStringSimpleFilter(targetAttribute, datasets)
          } 
          break;
        case 'long':
        case 'double':
          filter = makeNumericFilter(targetAttribute, datasets)
          break;
        case 'boolean':
          filter = makeBooleanFilter(targetAttribute, datasets)
          break;
        case 'timestamptz':
          filter = makeTimestampFilter(targetAttribute)
          break;
        case 'binary':
          filter = makeBinaryFilter(targetAttribute)
          break;
        default:
          break;
      }
    }
    
    const joinOptions = getJoinOptionsByPath(path, parentAttribute, datasets)
    if (joinOptions && joinOptions.datasets && joinOptions.datasets.length > 0) {
      filter = attachJoinOptionToFilter(filter, targetAttribute, joinOptions)
    }
    if (filter) {
      filters.push(filter)
    }
  })

  //Change the first non optional attribute if all attributes are optional 
  const allFiltersAreOptional = filters.every(filter => filter.value === 'default')
  if (filters.length > 0 && allFiltersAreOptional) {
    const indexOfFirstOptionalFilter = filters.findIndex(filter => filter.value === 'default' && filter.type !== 'binary')
    filters[indexOfFirstOptionalFilter].value = 'ifPresent'
  }

  return filters
}

function attachJoinOptionToFilter(filter, attribute, joinOptions) {
  if (!filter) {
    filter = {
      name: attribute.name,
      attributeDescription: attribute.description,
      type: "array",
      title: '',
      customTitle: attribute.path,
      path: attribute.path,
      description: '',
      value: attribute.required ? 'ifPresent' : 'default',
      options: [
        filter
      ]
    }
  } 
  filter.joinOption = {	
    config: {
      datasets: joinOptions.datasets.map(dataset => {
        const modifiedDataset = {...dataset}
        if (attribute.type === 'binary' && attribute.format === 'geometry') {
          modifiedDataset.format = 'geometry'
        }
        return modifiedDataset
      }),
      targetAttribute: joinOptions.attributeId,
      field: joinOptions.field
    },
    value: {
      joinType: 'include',
      selectedDataset: null
    }
  }
  if (attribute.type === 'binary' && attribute.format === 'geometry') {
    filter.joinOption.value.geometryType = 'Intersects'
  }
  filter.options.push({
    label: 'Join Dataset',
    value: 'join',
    tooltip: 'Include only rows that match the values in my dataset.'
  })
  if (joinOptions.parentAttribute) {
    filter.joinOption.parentAttribute = joinOptions.parentAttribute
  }
  return filter
}

let evaluateFilters = (filters) => {
  filters?.forEach(filter => {
    switch (filter.type) {
      case 'stringLimited':
        if (filter.value === 'custom' && !(filter.customOption.value.items.length > 0)) {
          filter.value = 'default'
        }
        break;
      case 'stringMany':
        const items = filter.customOption.value.manualEntry.split("\n").map(val => val.trim())
        if (filter.value === 'custom' && !(filter.customOption.value.manualEntry && items.length > 0)) {
          filter.value = 'default'
        }
        break;
      case 'number':
        if (filter.value === 'custom' && !(filter.customOption.value[0] || filter.customOption.value[1])) {
          filter.value = 'default'
        }
        break;
      case 'simpleTimestamp':
        if (filter.value === 'custom' && !(filter.customOption.value.start.enabled || filter.customOption.value.end.enabled || filter.customOption.value.recency.enabled)) {
          filter.value = 'default'
        }
        break;
      case 'boolean':
        if (filter.value === 'custom' && !(filter.customOption.value === true || filter.customOption.value === false)) {
          filter.value = 'default'
        }
        break;
      default:
        break;
    }
  })
}

let applyPreviousFilterState = (oldFilters = [], newFilters = []) => {
  const filterKeys =  getAllPathsSet(oldFilters?.map(filter => filter.path)) || new Set()
  const keyToFilterMapping = oldFilters?.reduce((acc, filter)=> {
    const key = getPropertyKey(filter?.path)
    acc[key] = filter
    return acc
  }, {})
  const filters = newFilters?.map(filter => {
    const key = getPropertyKey(filter.path)
    if(filterKeys.has(key)){
      return keyToFilterMapping[key]
    }
    return filter
  })
  return filters
}

export default {
  initFilters,
  makeDeduplicationFilter,
  makeFrequencyFilter,
  makeIngestionTimestampFilter,
  makeFilterableFieldsItems,
  getAllPathsSet,
  getPropertyKey,
  evaluateFilters,
  applyPreviousFilterState,
  applyPreviousFrequencyState,
  applyPreviousDeduplicationState,
  applyPreviousIngestionTimestampState,
  applyPreviousSparkState,
  updateDeduplication,
}