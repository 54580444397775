import { DefaultOption } from '../../options'

function makeBinaryFilter(attribute) {
  return { 
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "binary",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: 'default',
    options: [
      DefaultOption
    ]
  }
}

export default makeBinaryFilter