import { areSamePaths } from "@narrative.io/tackle-box/src/modules/app/schema/attributeModule"
import { makeRandomId } from '@narrative.io/tackle-box/src/modules/helpers'

let applyPreviousSparkState = (spark, filterable) => {
  spark.queries = spark.queries.filter(query => {
    const queryAttribute = query.query.find(element => element.value)
    if (queryAttribute?.value && filterable.find(path => areSamePaths(path, queryAttribute.value))) {
      return true
    }
    return false
  })
  if (!spark || !spark.queries || spark.queries.length < 1) {
    return {
      queries: [{
        id: makeRandomId(),
        query: []
      }]
    }
  }
  return spark
}

export {
  applyPreviousSparkState
}