import { DefaultOption, IncludeIfPresentOption, CustomOption } from '../../options'

function makeStringSimpleFilter(attribute) {
  return {
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "stringMany",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption,
      CustomOption
    ],
    customOption: {
      config: {
        manualEntryOnly: true
      },
      value: {
        listType: 'include',
        manualEntry: ''
      }
    }
  }
}

export default makeStringSimpleFilter