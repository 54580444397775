import { areSamePaths } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { getAllPathsSet, getPropertyKey } from '../../helpers'

let makeDeduplicationFilter = (filterObj, filters, deliverable) => {
  const deliverableFieldsSet = getAllPathsSet(deliverable)
  const filterCopy =  filterObj
  const options = []
  let selectedValues = []
  filters.filter(filter => !filter.path.includes('items')).forEach(filter =>{
    let textPath = ''
    filter.path.forEach((pathElement, index) => {
      if (index > 0) {
        textPath += ' > '
      }
      textPath += index === 0 ? pathElement.displayName : pathElement
    })

    const key = getPropertyKey(filter.path)
    const isDeliverable = deliverableFieldsSet.has(key)
    if (isDeliverable) {
      options.push({
        label: textPath,
        value: filter.path 
      })
    }
    
    // fiter is deliverable, not eventtimestamp and is required
    if (isDeliverable && filter?.name?.toLowerCase() !== "eventtimestamp" && filter.value !== "default") {
      selectedValues.push(filter.path)
    }
  })
  filterCopy.customOption.supportingOption.config.items = options
  filterCopy.customOption.supportingOption.value = selectedValues
  return filterCopy
}

let deduplicationFilterValid = (filter) => {
  const result = {
    formatValid: false,
    periodValid: false,
    pathsValid: false
  }

  if (filter.customOption.value[0] !== '' && filter.customOption.value[0].indexOf('.') > -1) {
    result.formatValid = false
  } else {
    result.formatValid = true
  }
  if (parseInt(filter.customOption.value) < 1 || filter.customOption.value[0] === '' || parseInt(filter.customOption.value) > 60) {
    result.periodValid = false
  } else {
    result.periodValid = true
  }
  if (filter.customOption.supportingOption.value?.length > 0) {
    result.pathsValid = true
  } else {
    result.pathsValid = false
  }

  return result
}

const DefaultDeduplicationFilter = {
  name: "deduplication",
  type: "frequency",
  title: "Deduplication",
  description: "Detail how often you want to buy the same data point.",
  value: "custom",
  options: [
    {
      label: `Include all Data Points`,
      value: 'default',
    },
    {
      label: 'Custom',
      value: 'custom',
    }
  ],
  customOption: {
    heading: 'Deduplication Strategy',
    description: 'Exclude irrelevant or redundant data even when you buy from multiple suppliers.',
    config: {
      periodOptions: [
        {
          label: 'Day(s)',
          value: 'D'
        }
      ]  
    },
    value: ['30', 'D'],
    supportingOption: {
      config: {
        text: 'Only receive a unique record once per period for the selected combination of data points',
        selectLabel: 'Data Points',
        items: []
      },
      value: null
    }
  }
}


let applyPreviousDeduplicationState = (oldDeduplication, filters, deliverable, oldDeliverable) => {
  const newDeduplication = makeDeduplicationFilter(JSON.parse(JSON.stringify(DefaultDeduplicationFilter)), filters, deliverable)
  const newItems = newDeduplication.customOption?.supportingOption?.config?.items
  const newSelections = newDeduplication?.customOption?.supportingOption?.value
  const oldSelections = oldDeduplication?.customOption?.supportingOption?.value
  if (oldDeduplication) {
    const updatedSelections = []
    oldSelections?.forEach(oldSelection => {
      const isAvailable = newItems?.find(item => areSamePaths(item.value, oldSelection)) !== undefined
      const isInUpdatedSelections = updatedSelections?.find(selectedItem => areSamePaths(selectedItem, oldSelection)) !== undefined
      if (isAvailable && !isInUpdatedSelections) {
        updatedSelections.push(oldSelection)
      }
    })
    newSelections?.forEach(newSelection => {
      const wasAvailable = oldDeliverable?.find(path => areSamePaths(path, newSelection)) !== undefined
      const isInUpdatedSelections = updatedSelections?.find(selectedItem => areSamePaths(selectedItem, newSelection)) !== undefined
      if (!wasAvailable && !isInUpdatedSelections) {
        updatedSelections.push(newSelection)
      }
    })
    newDeduplication.value = oldDeduplication.value
    newDeduplication.customOption.value = oldDeduplication.customOption.value
    newDeduplication.customOption.supportingOption.value = updatedSelections
  }
  return [newDeduplication]
}

let updateDeduplication = (deduplication, filters, prevFilters, deliverable) => {
  const deliverableFieldsSet = getAllPathsSet(deliverable)
  filters.forEach(filter => {
    const isAlreadySelected = deduplication.customOption.supportingOption.value?.find(selectedItem => areSamePaths(selectedItem, filter.path)) !== undefined
    if (!isAlreadySelected && deliverableFieldsSet.has(getPropertyKey(filter.path)) && filter?.name?.toLowerCase() !== "eventtimestamp" && filter.value !== "default") {
      const prevFilter = prevFilters?.find(currentPrevFilter => currentPrevFilter.name === filter.name )
      if (prevFilter?.value === 'default') {
        deduplication.customOption.supportingOption.value.push(filter.path)
      }
    }
  })
}

export {
  DefaultDeduplicationFilter,
  makeDeduplicationFilter,
  deduplicationFilterValid,
  applyPreviousDeduplicationState,
  updateDeduplication
}