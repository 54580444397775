import { 
  getSchemaPreset, 
  getAttributes, 
  getDatasets, 
  getSellerCompanies, 
  getDestinationOptions,
  getProcessingRate
} from '@/shared/fetchOperations'
import { 
  replacePropertyRefs, 
  makeSelected, 
  makeSelectedFromSchemaPreset, 
  setSelectionRecursively 
} from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

let initData = async (
  openApiInstance,
  openApiBaseUrl,
  openApiToken,
  schemaPresetId,
  initialSelectedAttributeIds
) => {
  try {
    const [
      attributesData,
      schemaPresetData,
      datasetsData,
      sellerCompaniesData,
      destinationOptionsData,
      contractRate
    ] = await Promise.all([
      getAttributes(openApiInstance), 
      getSchemaPreset(schemaPresetId, openApiInstance), 
      getDatasets(openApiInstance),
      getSellerCompanies(openApiInstance),
      getDestinationOptions(openApiToken, openApiBaseUrl, openApiInstance),
      getProcessingRate(openApiInstance)
    ])
    let results = {
      datasets: datasetsData,
      attributes: null,
      currentStep: null,
      schemaPreset: null,
      attributesStepPayload: null,
      steps: null,
      completedSteps: null,
      sellerCompanies: null,
      destinations: null,
      processingRate: null, 
      marketplaceFactor: null
    }
    results.attributes = attributesData.map(attribute => {
      return {
        ...replacePropertyRefs(attribute, attributesData),
        id: attribute.id,
        filterable: false,
        deliverable: false
      }
    })
    if (!schemaPresetData && initialSelectedAttributeIds?.length > 0) {
      initialSelectedAttributeIds.forEach(id => {
        const attribute = results.attributes.find(attribute => attribute.id === id)
        if (attribute) {
          setSelectionRecursively(attribute, "deliverable", true, true, attribute.required)
          setSelectionRecursively(attribute, "filterable", true, true, attribute.required)
          results.attributesStepPayload = {
            deliverable: makeSelected(results.attributes, 'deliverable'),
            filterable: makeSelected(results.attributes, 'filterable')
          }
          results.currentStep = 'attributes'
        }
      })
      localStorage.removeItem('nio:buyer-studio-custom-parameters')
    } else if (schemaPresetData) {
      results.schemaPreset = schemaPresetData
      const attributes = results.schemaPreset.details.attributes.map(attribute => results.attributes.find(rawAttribute => attribute.attribute_id === rawAttribute.id))
      const relevantAttributes = makeSelectedFromSchemaPreset(attributes, results.schemaPreset)
      results.attributesStepPayload = {
        deliverable: makeSelected(relevantAttributes, 'deliverable'),
        filterable: makeSelected(relevantAttributes, 'filterable')
      }
      results.completedSteps = ['attributes']
      results.currentStep = 'filters'
    } else {
      results.currentStep = 'attributes'
    }
    results.sellerCompanies = sellerCompaniesData.map(company => {
      return {
        label: company.name,
        value: company.id
      }
    }).sort((a, b) => (a.label > b.label) ? 1 : -1)
    results.destinations = destinationOptionsData.filter(destination => destination.appId !== 11) // temp fix to remove TTD connector until we solidify a way to handle allowed ingress/egress connectors
    // results.processingRate = processingRate.find(item => item.product.sku.includes('general-purpose-processing')).rate.rate.unit_price.price_in_micro_cents ? processingRate.find(item => item.product.sku.includes('general-purpose-processing')).rate.rate.unit_price.price_in_micro_cents : null
    results.processingRate = contractRate.find(rate => rate.product?.sku?.includes('general-purpose-processing'))?.rate?.unit_price?.value / 100
    if (results.processingRate === undefined || isNaN(results.processingRate)) {
      results.processingRate = 0.65
    }
    results.marketplaceFactor = (contractRate.find(rate => rate.product?.sku?.includes('marketplace-fee-buyer'))?.rate?.unit_price?.value + 100) / 100
    if (results.marketplaceFactor === undefined || isNaN(results.marketplaceFactor)) {
      results.marketplaceFactor = 1.25
    }
    return Promise.resolve(results)
  } catch (error) {
    return Promise.reject(error)
  }
}

export default initData