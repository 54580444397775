<template lang="pug">
  .attributes-step
    .header.step-header
      h2.nio-h2.text-primary-darker Select Attributes
      p.nio-p.text-primary-dark Choose the data attributes to include in your subscription.
    .step-loading(v-if="!attributes")
      v-progress-circular.progress(
        size="80" 
        indeterminate 
        color="#1438F5"
      )
    .attributes(v-else)
      SelectAttributes(
        :attributes="attributes"
        :filterable="filterable"
        :deliverable="deliverable"
        :initialOpenItemIds="initialSelectedAttributeIds"
        @payloadChanged="payloadChanged($event)"
      )
</template>

<script>

import SelectAttributes from '@/shared/components/attribute/SelectAttributes'

export default {
  components: { SelectAttributes },
  props: {
    "attributes": { type: Array, required: false, default: null},
    "filterable": { type: Array, required: false, default: [] },
    "deliverable": { type: Array, required: false, default: [] },
    "initialSelectedAttributeIds": { type: Array, required: false, default: () => [] }
  },
  mounted() {
    if (this.initialSelectedAttributeIds && this.initialSelectedAttributeIds.length > 0) {
      this.waitForAttributeRow().then((el) => {
        this.scrollToElement(el)
      })
    }
  },
  methods: {
    payloadChanged(event) {
      this.$emit('stepPayloadChanged', event)
    },
    pageChanged() {
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 80
        }
      },"*")
    },
    async waitForAttributeRow() {
      return new Promise((resolve, reject) => {
        let count = 0
        let interval = setInterval(() => {
          const attributeEl = document.getElementsByClassName(`nio-slat-table-item-id-${this.initialSelectedAttributeIds[0]}`)[0]
          if (attributeEl) {
            clearInterval(interval)
            resolve(attributeEl)
          }
          else {
            count++
            if (count > 100) {
              clearInterval(interval)
              reject()
            }
          }
        }, 100)
      })
    },
    scrollToElement(el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.nio-dialog
  z-index: 2
</style>
