<template lang="pug">
.confirmation-step
  .header
    h2.nio-h2.text-primary-darker Review Your Order
  .deliverable-data-costs
    .data-overview-filter 
      .title-description
        .filter-title.nio-h4.text-primary-darker Deliverable Data   
        .description.nio-p.text-primary-dark A cost breakdown of your dataset subscription.
      .filter-value
        .budget.filter
          .title-description
            .nio-p.text-primary-dark Budget 
          .filter-value 
            .nio-h5.text-primary-dark(v-if="costSummary.budget") {{ costSummary.budget }}
            //- v-progress-circular.progress(
            //-   v-else
            //-   size="20" 
            //-   color="#1438F5"
            //-   indeterminate 
            //- )
        NioDivider.subdivider(horizontal-solo)
        .estimated-deliverable-data.filter
          .title-description
            .nio-p.text-primary-dark Estimated Deliverable Data 
          .filter-value 
            .nio-h5.text-primary-dark(v-if="deliverableData && !forecastIsStale && !costForecastIsStale") {{ costSummary.estimatedDeliverableData }} rows
            v-progress-circular.progress(
              v-else
              size="20" 
              color="#1438F5"
              indeterminate 
            )
        NioDivider.subdivider(horizontal-solo)
        .data-cost.filter 
          .title-description
            .nio-p.text-primary-dark Data cost
          .filter-value 
            .nio-h5.text-primary-dark(v-if="costSummary.dataCost && !forecastIsStale && !costForecastIsStale") {{ costSummary.dataCost }}
            v-progress-circular.progress(
              v-else
              size="20" 
              color="#1438F5"
              indeterminate 
            )
        NioDivider.subdivider(horizontal-solo)
        .processing-cost.filter
          .title-description
            .nio-p.text-primary-dark Processing Cost
          .filter-value 
            .nio-h5.text-primary-dark(v-if="costSummary.processingCost && !forecastIsStale && !costForecastIsStale") {{ costSummary.processingCost }}
            v-progress-circular.progress(
              v-else
              size="20" 
              color="#1438F5"
              indeterminate 
            )
        NioDivider.subdivider(horizontal-solo)
        .transaction-fee.filter
          .title-description
            .nio-p.text-primary-dark Marketplace Transaction Fee 
          .filter-value 
            .nio-h5.text-primary-dark(v-if="costSummary.transactionFee && !forecastIsStale && !costForecastIsStale") {{ costSummary.transactionFee }}
            v-progress-circular.progress(
              v-else
              size="20" 
              color="#1438F5"
              indeterminate 
            )
        NioDivider.subdivider(horizontal-solo)
        .expected-total.filter
          .title-description
            .nio-p.text-primary-dark Expected Total
          .filter-value 
            .nio-h3.text-primary-dark(v-if="costSummary.expectedTotal && !forecastIsStale && !costForecastIsStale") {{ costSummary.expectedTotal }}
            v-progress-circular.progress(
              v-else
              size="20" 
              color="#1438F5"
              indeterminate 
            )
  .name-description
    .name-filter
      .title-description
        .filter-title.nio-h4.text-primary-darker Name <span class="text-error">(required)</span>      
        .description.nio-p.text-primary-dark Name your dataset for reference.
      .filter-value
        NioTextField(
          v-model="subscriptionName"
          :rules="[rules.required, rules.minLength]"
          label="Subscription Name"
          validate-on-blur
        )
    NioDivider.divider(horizontal-solo)
    .description-filter
      .title-description
        .filter-title.nio-h4.text-primary-darker Description
        .description.nio-p.text-primary-dark Enter a brief description about your dataset (optional).
      .filter-value
        NioTextarea(
          v-model="subscriptionDescription"
          :rows="3"
          hide-details
          placeholder="Subscription Description"
        )
        .cta  
          span.nio-p.text-primary-dark {{ ` For questions about your order, visit the ` }} 
            a.nio-p.bold(
              href="https://kb.narrative.io" 
              target="_blank"
            ) Help Center
          .download-notes.nio-p.text-primary-dark Your data will be available for download in 1-2 hours. Check the Onboarded Data page for updates.
</template>

<script>
import { formatCurrency, isValidPositiveNumber } from '@/modules/helpers'

export default {
  props: {
    "budgetData": { type: Object, required: false },
    "costSummary": { type: Object, required: false },
    "forecastIsStale": { type: Boolean, required: false },
    "costForecastIsStale": { type: Boolean, required: false }
  },
  data: () => ({
    chargeLineItems: [],
    subscriptionName: null,
    subscriptionDescription: null,
    rules: {
      required: function(value) { return !!value || 'Required' },
      minLength: function(value) { return value && value.length >= 4 || 'Must contain at least 4 characters' }
    }
  }),
  computed: {
    formattedBudget() {
      if (isValidPositiveNumber(this.budgetData.budgetAmount)) {
        return formatCurrency(parseInt(this.budgetData.budgetAmount))
      }
      else return false
    },
    deliverableData() {
      return this.costSummary.estimatedDeliverableData != null
    }
  },
  watch: {
    // budgetData(val) {
    //   if (val) {
    //     this.createLineItems()
    //   }
    //   this.update()
    // },
    subscriptionName(val) {
      if (val && val.length >= 4) {
        this.$emit('validChanged', true)
      } else {
        this.$emit('validChanged', false)
      }
      this.update()
    },
    subscriptionDescription(val) {
      this.update()
    }
  },
  mounted() {
    // this.createLineItems()
    this.$emit('stepPayloadChanged', {})
  },
  methods: {
    // createLineItems() {
    //   this.chargeLineItems = []
    //   this.chargeLineItems.push({ name: 'Monthly Budget', value: this.budgetData.budgetAmount })
    // },
    update() {
      this.$emit('stepPayloadChanged', {
        name: this.subscriptionName,
        description: this.subscriptionDescription,
        lineItems: this.chargeLineItems
      })
    }
  }
};
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"
.confirmation-step
  display: flex
  flex-direction: column
  align-items: center
  .nio-h2
    margin-bottom: 0.5rem
  .header
    margin-bottom: 1.5rem
    flex-direction: column
    align-items: center
  .download
    margin-top: 1.5rem
  .name-description
    width: 100%
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
    .divider
      padding-left: 1.5rem
      padding-right: 1.5rem
  .charges-fees
    .nio-h3
      text-align: center
      margin: 1.5rem auto 1.5rem auto 
  .name-filter, .description-filter
    +nio-filter-header  
    padding: 1.5rem
    .nio-text-field
      width: 100%
  .description-filter
    .filter-value
      .cta
        margin-top: 1rem
        padding-top: 0.75rem
        padding-bottom: 0.75rem
        .download-notes
          margin-top: 1.75rem
        a
          text-decoration: none
          color: $c-primary  
  .deliverable-data-costs
    width: 100%
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
    .data-overview-filter
      +nio-filter-header  
      padding: 1.5rem
      .filter-value
        width: 50%
        .subdivider
          margin: 0rem
          height: 0.125rem
        .budget, .estimated-deliverable-data, .data-cost, .processing-cost, .transaction-fee, .expected-total
          width: 100%
          display: flex
          flex-direction: row
          margin-bottom: 0.875rem
        .estimated-deliverable-data, .data-cost, .processing-cost, .transaction-fee, .expected-total
          margin-top: 0.75rem
</style>