<template lang="pug">
  .destination-step
    .header.step-header
      h2.nio-h2.text-primary-darker Choose where to send your data
    .step-loading(v-if="!destinations")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate
      )
    NioSelectDestination(
      v-else-if="destinations"
      :destinations="destinations"
      @validChanged="validChanged($event)"
    )
</template>

<script>

export default {
  props: {
    "completedSummary": { type: Object, required: false },
    "destinations": { type: Array, required: false }
  },
  data: () => ({
    loading: true,
    valid: false
  }),
  watch: {
    destinations: {
      deep: true,
      handler() {
        this.updatePayload()
      }
    },
    valid() {
      this.updatePayload()
    }
  },
  mounted() {
    this.updatePayload()
  },
  methods: {
    validChanged(value) {
      this.valid = value
    },
    updatePayload() {
      this.$emit('stepPayloadChanged', {
        destinations: this.destinations,
        valid: this.valid
      })
    }
  }
};
</script>

<style lang="sass" scoped>
  .destination-step
    .header
      margin-bottom: 1.5rem
      flex-direction: column
      align-items: center
      h2
        margin-bottom: 0.5rem
      p 
        text-align: center
    .summary
      margin: 0 auto 1.5rem auto
</style>