import moment from 'moment'

function makeIngestionTimestampFilter(filterObj) {
  const filterCopy = filterObj
  return filterCopy
}

const DefaultIngestionTimestampFilter = { 
  name: "ingestionTimestamp",
  type: "simpleTimestamp",
  title: "Ingestion Timestamp",
  description: "Filter on the date that a provider's data was ingested through Dataset Manager. The ingestion timestamp filter is independent of the event timestamp attribute. (if selected)",
  value: 'default',
  options: [
      {
      label: 'No ingestion filter',
      value: 'default',
    },
    {
      label: "Custom",
      value: 'custom',
    }
  ],
  customOption: {
    config: {
      periodOptions: [
        {
          label: 'Day(s)',
          value: 'D'
        },
        {
          label: 'Week(s)',
          value: 'W'
        },
        {
          label: 'Month(s)',
          value: 'M'
        }
      ]  
    },
    value: {
      recency: {
        enabled: false,
        period: 'D',
        value: 30
      },
      start: {
        enabled: true,
        inclusive: false,
        timestamp: moment(new Date()).format('YYYY-MM-DD'),
      },
      end: {
        enabled: true,
        inclusive: false,
        timestamp: moment(new Date()).add("days", 1).format('YYYY-MM-DD')
      } 
    }
  }
}

let applyPreviousIngestionTimestampState = (prevFilter) => {
  let newFilter = makeIngestionTimestampFilter(JSON.parse(JSON.stringify(DefaultIngestionTimestampFilter)))
  if (prevFilter) {
    newFilter.value = prevFilter.value
    newFilter.customOption = prevFilter.customOption
  }
  return [newFilter]
}

export {
  DefaultIngestionTimestampFilter,
  makeIngestionTimestampFilter,
  applyPreviousIngestionTimestampState
}