<template lang="pug">
  .schema-preset-summary(
    v-if="schemaPreset"
    :style="{backgroundColor: backgroundColor}"
  )
    .name-description
      h1.nio-h1.text-white {{ schemaPreset.name }}
      p.nio-p.text-white {{ schemaPreset.description }}
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'

export default {
  props: {
    "schemaPreset": { type: Object, required: true }
  },
  computed: {
    backgroundColor() {
      return this.schemaPreset.display_color ? this.schemaPreset.display_color : getThemeColor('primaryDark')
    }
  }
}
</script>

<style lang="sass" scoped>

.schema-preset-summary
  padding: 1.5rem 2rem
  display: flex
  justify-content: space-between
  align-items: center
  .name-description
    width: 50%
    display: flex
    flex-direction: column
 
</style>