
let makeFilterableFieldsItems = (filters) => {
  const filterable =  filters.filter(filter => !filter.path.includes('items')).map(filter => {
    let label = ''
    filter.path.forEach((element, index) => {
      if (index === 0) {
        label += element.displayName
      } else {
        label += ` > ${element}`
      }
    })
    return {
      label: label,
      value: filter.path
    }
  })
  return filterable
}

let getAllPathsSet = (paths) => {
  return paths?.reduce((set, path) => {
    const key = getPropertyKey(path)
    set.add(key)
    return set
  }, new Set())
}

let getPropertyKey =(path = []) => {
  const [pathInfo, propertyName] = path
  return  propertyName ? pathInfo?.id + propertyName: pathInfo.id
}

export {
  makeFilterableFieldsItems,
  getAllPathsSet,
  getPropertyKey
}