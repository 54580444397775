import { DefaultOption, IncludeIfPresentOption, CustomOption } from '../../options'

function makeBooleanFilter(attribute) {
  return { 
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "boolean",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption,
      CustomOption
    ],
    customOption: {
      value: true
    }
  }
}

export default makeBooleanFilter