import { DefaultOption, IncludeIfPresentOption, CustomOption } from '../../options'
import moment from 'moment'

function makeTimestampFilter(attribute) {
  return {
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "simpleTimestamp",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption,
      CustomOption
    ],
    customOption: {
      config: {
        periodOptions: [
          {
            label: 'Day(s)',
            value: 'D'
          },
          {
            label: 'Week(s)',
            value: 'W'
          },
          {
            label: 'Month(s)',
            value: 'M'
          }
        ]  
      },
      value: {
        recency: {
          enabled: false,
          period: 'D',
          value: 30
        },
        start: {
          enabled: true,
          inclusive: false,
          timestamp: moment(new Date()).format('YYYY-MM-DD'),
        },
        end: {
          enabled: true,
          inclusive: false,
          timestamp: moment(new Date()).add("days", 1).format('YYYY-MM-DD')
        } 
      }
    },
    valid: true
  }
}  

export default makeTimestampFilter