<template lang="pug">
  .providers-step
    .header.step-header
      h2.nio-h2.text-primary-darker Pick Providers
      p.nio-p.text-primary-dark Select providers you want to buy from or exclude.
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .providers-filter(v-if="!loading")
      NioFilter(
        :filter="filter"
        solo
      )
</template>

<script>

export default {
  props: {
    "providers": { type: Array, required: true, default: null}
  },
  data: () => ({
    loading: true,
    filter: {
      name: "providers",
      type: "stringLimited",
      title: "Providers",
      value: "default",
      options: [
        {
          label: `Buy from all sellers`,
          value: 'default',
        },
        {
          label: 'Custom',
          value: 'custom',
        }
      ],
      customOption: {
        config: {
          searchable: true,
          multiple: true,
          items: null,
          text: {
            include: 'Select the companies you want to buy from.',
            exclude: 'Select the companies you don’t want to buy from.'
          }
        },
        value: {
          listType: 'include',
          items: []
        }  
      }
    }
  }),
  watch: {
    filter: {
      deep: true,
      handler() {
        this.updatePayload()
      }
    }  
  },
  mounted() {
    this.filter.customOption.config.items = this.providers
    this.loading = false
    this.updatePayload()
  },
  methods: {
    updatePayload() {
      this.$emit('stepPayloadChanged', {
        selection: this.filter.options.find(option => option.value === this.filter.value).value,
        listType: this.filter.value === 'custom' ? this.filter.customOption.value.listType : '',
        numProviders: this.filter.customOption.value.items.length,
        companyIds: this.filter.customOption.value.items
      })
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
.providers-step
  .step-header
    margin-bottom: 0rem
  ::v-deep .nio-filter-property
    background-color: $c-white
    border-top: none !important
    .heading-description
      display: none
    .custom-option  
      border: 0.0625rem solid $c-primary-lighter
      border-radius: 0.75rem
      .option-content
        padding: 2rem 1.5rem !important
        background-color: $c-canvas !important
</style>
