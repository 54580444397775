<template lang="pug">
  .new-page  
    .header.app-header
      h1.nio-h1.text-primary-darker New Data Subscription
      NioButton(
        caution-text 
        @click="cancel"
      ) Cancel
    NioForecastWidget(
      v-if="currentStep !== 'attributes' && !loading && openApiBaseUrl && openApiToken"
      ref="forecast"
      :forecast-params="forecastParams"
      :app-type="'buyer'"
      :filters="filters"
      :open-api-base-url="openApiBaseUrl"
      :open-api-token="openApiToken"
      :hide-stale-forecast-message="true"
      :forecast-params-stale="true"
      :processing-rate="processingRate"
      @forecastStarted="forecastUpdating()"
      @forecastComplete="forecastComplete($event)"
      @costForecastComplete="costForecastComplete($event)"
    )
    .app-loading(v-if="loading")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    NioStepper(
      v-else
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      final-step-label="Activate Subscription"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="createSubscription"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        v-if="steps.includes('attributes')"
        :valid="deliverable && deliverable.length > 0"
        :summary="makeStepSummary('attributes')"
        step-name="attributes"
        custom-summary
      )
        template(v-slot:custom-summary)
          .dual-summary
            .entry.deliverable
              .nio-h4.text-primary-darker {{ deliverable.length }} deliverable attribute{{ deliverable.length !== 1 ? 's' : ''}}
            .entry.filterable
              .nio-h4.text-primary-darker {{ filterable.length }} filterable attribute{{ filterable.length !== 1 ? 's' : ''}}
        template(v-slot:content)
          NioDivider(horizontal-solo)
          AttributesStep(
            :attributes="attributes"
            :deliverable="stepPayloads.attributes  ? deliverable : []"
            :filterable="stepPayloads.attributes ? filterable : []"
            :initialSelectedAttributeIds="initialSelectedAttributeIds"
            @stepPayloadChanged="updatePayload('attributes', $event)"
            @setStepIncomplete="setStepIncomplete('attributes')"
          )
      NioStep(
        :valid="isFiltersStepValid"
        :summary="makeStepSummary('filters')"
        step-name="filters"
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          FiltersStep(
            :filters="filters"
            :schema-preset="schemaPreset"
            :price-cap="priceCap"
            :price-cap-valid="priceCapValid"
            :frequency="frequency"
            :ingestion-timestamp="ingestionTimestamp"
            :rescan-data="rescanData"
            :filterable="filterable"
            :filterable-fields-items="filterableFieldsItems"
            :frequency-valid="frequencyValid"
            :spark="spark"
            :spark-queries-valid="sparkQueriesValid"
            :ingestion-timestamp-valid="ingestionTimestampValid"
            :checking-spark-queries="checkingSparkQueries"
            :deduplication="deduplication"
            :deduplication-period-valid="deduplicationPeriodValid"
            :deduplication-format-valid="deduplicationFormatValid"
            :deduplication-paths-valid="deduplicationPathsValid"
            :filters-payload="stepPayloads.filters"
            :data-rules-params="dataRulesParams"
            @disableFiltersForSparkQueries="setDisabledFilters"
            @setSparkQueriesValid="setSparkQueriesValid($event)"
            @checkingSparkQueries="checkingSparkQueries = true"
            @checkingSparkQueriesComplete="checkingSparkQueries = false"
            @stepPayloadChanged="updatePayload('filters', $event)"
            @setStepIncomplete="setStepIncomplete('filters')"
            :hasFilterable="hasFilterable"
          )
      NioStep(
        :valid="stepPayloads.providers !== null"
        :summary="makeStepSummary('providers')"
        step-name="providers"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          NioDivider(horizontal-solo)
          ProvidersStep(
            v-if="sellerCompanies"
            :providers="formattedSellerCompanies"
            @stepPayloadChanged="updatePayload('providers', $event)"
          )    
      NioStep(
        :valid="stepPayloads.destination !== null && stepPayloads.destination.valid"
        :summary="makeStepSummary('destination')"
        step-name="destination"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          NioDivider(horizontal-solo)
          DestinationStep(
            :destinations="destinations"
            :completed-summary="makeStepSummary('destination')"
            @stepPayloadChanged="updatePayload('destination', $event)"
          )
      NioStep(
        :valid="budgetValid && stepPayloads.payment !== null"
        :summary="makeStepSummary('payment')"
        step-name="budget and delivery cadence"
        custom-summary
      )
        template(v-slot:custom-summary)
          .dual-summary(v-if="budgetSummary && showPayment")
            .entry
              .nio-h4.text-primary-darker {{ budgetSummary.budget }}
            .entry
              .nio-h4.text-primary-darker Deliver {{ budgetSummary.delivery }} 
            .entry
              .nio-h4.text-primary-darker Pay with {{ stepPayloads.payment.title }} 
        template(v-slot:content)
          NioDivider(horizontal-solo)
          BudgetStep(
            v-if="stepPayloads.filters !== null"
            :completed-summary="makeStepSummary('budget')"
            :processing-rate="processingRate"
            :marketplace-factor="marketplaceFactor"
            :forecast-results="forecastResults"
            :cost-forecast-results="costForecastResults"
            :forecast-is-stale="forecastIsStale"
            :cost-forecast-is-stale="costForecastIsStale"
            @costsEstimated="updateCostSummary($event)"
            @stepPayloadChanged="updatePayload('budget', $event)"
          )
          NioDivider(horizontal-solo)
          PaymentStep(
            @stepPayloadChanged="updatePayload('payment', $event)"
          )
      NioStep(
        :valid="stepPayloads.confirmation !== null && confirmationValid"
        step-name="confirmation"
      )
        template(v-slot:header-complete)
        template(v-slot:content)
          NioDivider(horizontal-solo)
          .creating-subscription(v-if="loading")
            v-progress-circular.progress(
              size="80" 
              color="#1438F5"
              indeterminate 
            )
          ConfirmationStep(
            :budget-data="stepPayloads.budget"
            :cost-summary="costSummary"
            :forecast-is-stale="forecastIsStale"
            :cost-forecast-is-stale="costForecastIsStale"
            @stepPayloadChanged="updatePayload('confirmation', $event)"
            @validChanged="confirmationValidChanged($event)"
          )
    NioDialog(
      v-model="cancelDialog" 
    )
      CancelDialog(
        @cancel="cancelDialog = false"
        @confirm="confirmCancellation"
      ) 
    NioDialog(
      v-model="errorDialog" 
    )
      ErrorDialog(
        @close="errorDialog = false"
      )     
</template>

<script>

import { getAttributeFromPath, areSamePaths } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import filtersModule from '@/modules/filtersModule'
import AttributesStep from './steps/attributes/AttributesStep'
import ProvidersStep from './steps/providers/ProvidersStep'
import BudgetStep from './steps/budget/BudgetStep'
import ConfirmationStep from './steps/confirmation/ConfirmationStep'
import DestinationStep from './steps/destination/DestinationStep'
import PaymentStep from './steps/payment/PaymentStep'
import FiltersStep from './steps/filters/FiltersStep'
import CancelDialog from './CancelDialog'
import ErrorDialog from './ErrorDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { makeDataRules } from './createSubscription' 
import { mapGetters } from 'vuex'
import { isValidPositiveNumber } from '@/modules/helpers'
import {
  makeDeliveryCadence,
  makeBudgetCadence,
  makeBudgetSummary
} from '@/modules/budget'
import { deduplicationFilterValid } from '@/modules/filters/types/advanced/deduplication'
import { frequencyFilterValid } from '@/modules/filters/types/advanced/frequency'
import removeUnsupportedSelections from './helpers/removeUnsupportedSelections'
import initData from './helpers/initData'
import makeForecastParams from './helpers/makeForecastParams'
import makeStepSummary from './helpers/makeStepSummary'
import selectionsChanged from './helpers/selectionsChanged'

export default {
  components: { 
    AttributesStep, 
    ProvidersStep, 
    BudgetStep, 
    ConfirmationStep, 
    DestinationStep, 
    PaymentStep, 
    FiltersStep, 
    CancelDialog, 
    ErrorDialog
  },
  props: ["schemaPresetId"],
  data: () => ({
    schemaPreset: null,
    sellerCompanies: null,
    datasets: null,
    steps: ['attributes', 'filters', 'providers', 'destination', 'budget and delivery cadence', 'confirmation'],
    currentStep: null,
    completedSteps: [],
    filters: null,
    prevFilters: null,
    prevFilterable: [],
    filterable: [],
    filterableFieldsItems: null,
    coreFiltersValid: true,
    costSummary: {
      budget: null,
      dataCost: null,
      estimatedDeliverableData: null,
      expectedTotal: null,
      transactionFee: null,
      processingCost: null
    },
    stepPayloads: {
      attributes: null,
      filters: null,
      providers: null,
      destination: null, 
      budget: null,
      payment: null,
      confirmation: null
    },
    errorDialog: false,
    cancelDialog: false,
    rawAttributes: null,
    attributes: null,
    loading: true,
    confirmationValid: false,
    priceCap: 1.00, 
    deduplication: null,
    deduplicationPeriodValid: true,
    deduplicationFormatValid: true,
    deduplicationPathsValid: false,
    frequency: null, 
    frequencyValid: true,
    spark: {
      queries: [{
        id: 1,
        query: []
      }]
    },
    ingestionTimestamp: null,
    ingestionTimestampValid: true,
    showPayment: false,
    sparkLastValidated: null,
    sparkQueriesValid: true,
    checkingSparkQueries: false,
    destinations: null,
    selectedDestinations: null,
    prevSubscriptionParams: null,
    forecastParams: null,
    prevDeliverable: [],
    deliverable: [],
    customParams: null,
    initialSelectedAttributeIds: null,
    openApiBaseUrl: null,
    openApiToken: null,
    forecastResults: null,
    costForecastResults: null,
    forecastIsStale: null,
    costForecastIsStale: null,
    processingRate: null,
    marketplaceFactor: null
  }),
  computed: {
    ...mapGetters(['user']),
    formattedSellerCompanies() {
      return this.sellerCompanies.map(provider => { return { label: `${provider.label} (${provider.value})`,value: provider.value } })
    },
    hasFilterable() {
      return this.filterable && this.filterable.length > 0;
    },
    isFiltersStepValid() {
      if (this.deliverable && this.deliverable.length > 0 && !this.hasFilterable){
        return true
      }
      return this.priceCapValid
        && this.coreFiltersValid 
        && this.frequencyValid 
        && this.ingestionTimestampValid
        && this.sparkQueriesValid 
        && !this.checkingSparkQueries 
        && (this.deduplication 
          && this.deduplication[0].value === 'default' || (
            this.deduplicationFormatValid 
            && this.deduplicationPeriodValid 
            && this.deduplicationPathsValid
          )
        )
    },
    hasSparkQueries() {
      return this.spark.queries.find(query => query.query.length > 0) !== undefined
    },
    hasDeduplicationError() {
      return (this.deduplication && this.deduplication[0]?.value === 'custom' &&  (!this.deduplicationFormatValid || 
          !this.deduplicationPathsValid || !this.deduplicationPeriodValid))
    },
    rescanData() {
      return this.stepPayloads.filters?.rescanData
    },
    priceCapValid() {
      return this.priceCap >= 0
    },
    budgetValid() {
      return isValidPositiveNumber(this.stepPayloads.budget?.budgetAmount) && this.costSummary?.budget
    },
    budgetSummary() {
      return makeBudgetSummary(
        this.stepPayloads?.budget?.budgetAmount,
        this.stepPayloads?.budget?.budgetCadence,
        this.stepPayloads?.budget?.deliveryCadence
      )
    },    
    dataRulesParams() {
      return {
        filterable: this.filterable,
        deliverable: this.deliverable,
        filters: this.filters,
        attributes: this.attributes,
        deduplication: this.hasFilterable && this.deduplication  ? this.deduplication[0] : {},
        frequency: this.frequency,
        ingestionTimestamp: this.ingestionTimestamp
      }
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        filtersModule.updateDeduplication(this.deduplication[0], this.filters, this.prevFilters, this.deliverable)
        this.checkFiltersValid()
        this.prevFilters = JSON.parse(JSON.stringify(this.filters))
      }
    },
    deduplication: {
      deep: true,
      handler(val) {
        this.checkDeduplication(val)
        this.makeSubscription()
      }
    },
    frequency: {
      deep: true,
      handler(val) {
        this.checkFrequency(val)
        this.makeSubscription()
      }
    },
    ingestionTimestamp: {
      deep: true,
      handler(val) {
        this.checkIngestionTimestamp(val)
        this.makeSubscription()
      }
    }
  },
  mounted() {
    if (localStorage.getItem('nio:buyer-studio-custom-parameters')) {
      this.customParams = JSON.parse(localStorage.getItem('nio:buyer-studio-custom-parameters'))
      this.initialSelectedAttributeIds = [this.customParams.attributeId]
    }
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    async openApiInit(token, baseURL) {
      this.openApiBaseUrl = NioOpenApiModule.getBaseUrl()
      this.openApiToken = NioOpenApiModule.getToken()
      const initialData = await initData(
        this.$nioOpenApi, 
        this.openApiBaseUrl, 
        this.openApiToken, 
        this.schemaPresetId,
        this.initialSelectedAttributeIds
      )
      if (initialData.datasets) this.datasets = initialData.datasets
      if (initialData.attributes) this.attributes = initialData.attributes
      if (initialData.steps) this.steps = initialData.steps
      if (initialData.completedSteps) this.completedSteps = initialData.completedSteps
      if (initialData.sellerCompanies) this.sellerCompanies = initialData.sellerCompanies
      if (initialData.destinations) this.destinations = initialData.destinations
      if (initialData.processingRate) this.processingRate = initialData.processingRate
      if (initialData.marketplaceFactor) this.marketplaceFactor = initialData.marketplaceFactor
      if (initialData.attributesStepPayload) {
        this.stepPayloads.attributes = initialData.attributesStepPayload
        this.updatePayload('attributes', initialData.attributesStepPayload)
      }
      if (initialData.currentStep) {
        this.currentStep = initialData.currentStep
      } 
      if (initialData.schemaPreset) {
        this.initFilters()
        this.schemaPreset = initialData.schemaPreset
        this.steps = this.steps.filter(stepName => stepName !== 'attributes')
      }
      this.loading = false
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      if (this.currentStep === 'filters') {
        this.initFilters()
      }
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
        if (this.currentStep === 'filters') {
          if (!this.stepPayloads.filters && this.coreFiltersValid) {
            this.stepPayloads.filters =  {
              ...this.stepPayloads.filters,
              filters: this.filters
            }
          } else {
            filtersModule.evaluateFilters(this.stepPayloads.filters.filters)
          }
        }
      }
      if (this.currentStep === 'attributes' || this.currentStep === 'filters' || this.currentStep === 'providers' || this.currentStep === 'destination') {
        this.showPayment = false
      } else {
        this.showPayment = true
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      if (this.currentStep === 'filters') {
        this.initFilters()
      }
      if (this.currentStep === 'destination') {
        this.$refs.forecast.generateForecast()
      }
      this.makeSubscription()  
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
      if (stepName === 'filters') {
        this.initFilters()
      }
    },
    setStepIncomplete(stepName) {
      const stepIndex = this.steps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => index < stepIndex)
    },
    stepComplete(stepName) {
      return this.completedSteps.includes(stepName)
    },
    makeSubscription() {
      this.$nextTick(() => {
        this.checkDeduplication(this.deduplication)
        this.checkIngestionTimestamp(this.ingestionTimestamp)
        const params = this.makeSubscriptionParams()
        const updated = JSON.stringify(this.prevSubscriptionParams) !== JSON.stringify(params)   
        if (params.details && updated) {
          this.prevSubscriptionParams = params
          this.forecastParams = makeForecastParams(
            params,
            this.coreFiltersValid,
            this.hasDeduplicationError,
            this.frequencyValid,
            this.ingestionTimestampValid,
            this.nioUser?.role
          )
        }
      })
    }, 
    initFilters() {
      if (selectionsChanged(this.deliverable, this.prevDeliverable) || selectionsChanged(this.filterable, this.prevFilterable)) {
        if (!this.stepPayloads.filters && this.stepPayloads.attributes) {
          const newFilters = filtersModule.initFilters(this.filterable, this.attributes, this.datasets)
          this.filters = filtersModule.applyPreviousFilterState(this.filters, newFilters)
        }
        this.deduplication = filtersModule.applyPreviousDeduplicationState(this.deduplication ? this.deduplication[0] : null, this.filters, this.deliverable, this.prevDeliverable)
        this.frequency = filtersModule.applyPreviousFrequencyState(this.frequency ? this.frequency[0] : null, this.filters) 
        this.ingestionTimestamp = filtersModule.applyPreviousIngestionTimestampState(this.ingestionTimestamp ? this.ingestionTimestamp[0] : null)
        this.filterableFieldsItems = filtersModule.makeFilterableFieldsItems(this.filters)
        this.spark = filtersModule.applyPreviousSparkState(this.spark, this.filterable)
        this.prevFilterable = this.filterable
        this.prevDeliverable = this.deliverable
      }
      this.makeSubscription()
      this.stepPayloads.filters = {
        priceCap: this.priceCap ? 
          this.priceCap : 1.00,
        filters: this.filters,
        deduplication: this.deduplication 
      }
    },
    updatePayload(step, payload) {
      this.stepPayloads[step] = payload
      if (step === 'attributes') {
        this.stepPayloads.attributes.deliverable = this.stepPayloads.attributes.deliverable.filter(path => {
          const parentAttribute = this.attributes.find(attribute => attribute.id === path[0].id)
          const attribute = getAttributeFromPath(path, parentAttribute)
          return attribute.type !== 'binary'
        }) 
        this.filterable = removeUnsupportedSelections(this.stepPayloads.attributes.filterable, this.attributes)
        this.deliverable = removeUnsupportedSelections(this.stepPayloads.attributes.deliverable, this.attributes)
        this.stepPayloads.filters = null
        this.stepPayloads.budget = null
        this.costSummary = null
        this.forecastIsStale = true
        this.costForecastIsStale = true
        this.completedSteps = this.completedSteps.filter(step => step !== 'filters')
      } else if (step === 'filters') {
        this.priceCap = payload.priceCap
        this.makeSubscription()
      } else if (step === 'providers') {
        this.makeSubscription()
      } else if (step === 'destination') {
        this.makeSubscription()
        this.selectedDestinations = payload.destinations?.filter(destination => destination.selected)
      } 
    },
    checkCoreFiltersValid(filters) {
      if (filters?.filter(filter => filter.value === 'join').length > 1 || !filters?.find(filter => filter.value !== "default" )) {
        return false
      }
      return filters?.length && filters.find(filter => filter.valid === false) === undefined
    },
    checkDeduplication(val) {
      const validityResult = deduplicationFilterValid(val[0])
      this.deduplicationFormatValid = validityResult.formatValid
      this.deduplicationPeriodValid = validityResult.periodValid
      this.deduplicationPathsValid = validityResult.pathsValid
    },
    checkFrequency() {
      this.frequencyValid = frequencyFilterValid(this.frequency[0])
    },
    checkIngestionTimestamp() {
      this.ingestionTimestampValid = this.ingestionTimestamp[0].value === 'default' || this.ingestionTimestamp[0].valid
    },
    checkFiltersValid() {
        this.$nextTick(() => {
          this.coreFiltersValid = this.checkCoreFiltersValid(this.filters)
          if (!this.coreFiltersValid) {
            this.setStepIncomplete('filters')
          }
          this.makeSubscription()
      }) 
    },
    makeSubscriptionParams() {
      const deduplication = this.hasFilterable && this.deduplication  ? this.deduplication[0] : {}
      const params = {
        budget: {
          amount: {
            value: this.stepPayloads.budget?.budgetAmount,
            currency: "USD"
          },
          period: {
            type: makeBudgetCadence(this.stepPayloads?.budget?.budgetCadence)
          }
        },
        description: this.stepPayloads.confirmation?.description,
        name: this.stepPayloads.confirmation?.name,
        details: {
          type: "marketplace",
          data_rules:  makeDataRules(
            this.filterable, 
            this.deliverable, 
            this.filters, 
            this.attributes, 
            deduplication,
            this.frequency, 
            this.ingestionTimestamp,
            this.spark
          ),
          pricing: {
            type: "max_price_per_record",
            micro_cents_usd: parseInt(this.priceCap / 1000 * 1000000 * 100)
          }     
        },
        cadence: makeDeliveryCadence(this.stepPayloads?.budget?.deliveryCadence)
      }
      if (this.stepPayloads.providers?.selection === 'custom' && this.stepPayloads.providers?.numProviders > 0) {
        params.details.company_constraint = {
          type: this.stepPayloads.providers.listType === 'include' ? 'inclusion' : 'exclusion',
          company_ids: this.stepPayloads.providers.companyIds
        }   
      }
      if (this.rescanData) {
        params.read_once = false
      }
      return params
    },
    createSubscription() {
      const orderParameters = this.makeSubscriptionParams()
      this.loading = true
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.$nioOpenApi.post(`/data-shops/subscriptions/checkout`, JSON.stringify(orderParameters)).then(resp => {
        if (resp.status === 200 || resp.status === 201) {
          if (this.selectedDestinations.length > 1) {
            const destinations = this.selectedDestinations.filter(destination => destination.name !== 'Narrative Download')
            let subscriptionId = resp.data.id
            let fromDataset = false
            if (resp?.data?.output?.dataset_id) {
              subscriptionId = resp.data.output.dataset_id
              fromDataset = true
            }
            Promise.all(destinations.map(destination => {    
              return this.makeDestinationRequest(subscriptionId, destination, fromDataset)
            })).then(results => {
              if (results.filter(result => !result || result !== 'success').length > 0) {
                this.errorDialog = true
                this.loading = false
                this.$nioOpenApi.delete(`/data-shops/subscriptions/${resp.data.id}`)
              } else {
                this.loading = false
                try {
                  parent.postMessage({
                    name: 'hubspotAnalyticsEvent',
                    payload: {
                      eventName: 'buyerStudioSubcriptionCreated',
                      params: {
                        budget: parseFloat(orderParameters.budget.amount.value).toFixed(2),
                        maxCpm: parseFloat(orderParameters.details.pricing.micro_cents_usd / 100000).toFixed(2)
                      }
                    }
                  },"*")
                } catch {}
                parent.postMessage({
                  name: 'pageNavigation',
                  payload: 'subscriptions'
                },"*")
              }
            }, err => {
              this.$nioOpenApi.delete(`/data-shops/subscriptions/${resp.data.id}`)
              this.errorDialog = true
              this.loading = false
            })
          } else {
            this.loading = false
            try {
              parent.postMessage({
                name: 'hubspotAnalyticsEvent',
                payload: {
                  eventName: 'buyerStudioSubcriptionCreated',
                  params: {
                    budget: parseFloat(orderParameters.budget.amount.value).toFixed(2),
                    maxCpm: parseFloat(orderParameters.details.pricing.micro_cents_usd / 100000).toFixed(2)
                  }
                }
              },"*")
            } catch(e) { console.log(e) }
            parent.postMessage({
              name: 'pageNavigation',
              payload: 'subscriptions'
            },"*")
          }
        }
      }).catch((err) => {
        this.loading = false
        this.errorDialog = true
      })
    },
    makeDestinationRequest(subscriptionId, destination, fromDataset) {
      return new Promise((resolve, reject) => {
        let payload
        if (destination.appId === 7 || destination.appId === 8) {
          payload = {
            profile_id: destination.selectedProfile,
            quick_settings: {}
          }
          if (fromDataset) {
            payload.dataset_id = subscriptionId
          } else {
            payload.subcription_id = subscriptionId
          }
          destination.quickSettings.map(setting => {
            if (setting.value) {
              payload.quick_settings[setting.id] = setting.value
            }
          })
          const endpoint = fromDataset ? '/connections' : '/destinations'
          this.$nioOpenApi.post(endpoint, payload).then(resp => {
            if (resp.status !== 200 && resp.status !== 201) {
              resolve()
            } else {
              resolve('success')
            }
          }, err => {
            resolve()
          })
          
        } else {
          // unknown destination
          resolve()
        }
      })
    },
    cancel() {
      this.cancelDialog = true
    },
    confirmCancellation() {
      this.stepPayloads = {
        attributes: null,
        source: null,
        match: null,
        destination: null, 
        budget: null,
        payment: null,
        confirmation: null
      }
      this.completedSteps = []
      this.currentStep = 'source'
      this.cancelDialog = false
    },
    scrollToStep(stepIndex) { 
      this.$nextTick(() => {
        const top = stepIndex === 0 || stepIndex === 1 ? 35 + stepIndex * 130 : 250 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    },
    confirmationValidChanged(val) {
      this.confirmationValid = val
    },
    setDisabledFilters() {
      const filters = JSON.parse(JSON.stringify(this.filters))
      const uniqueAttributes = this.spark.queries.reduce((acc, query) => {
        const thisQuery = query.query
        const attributeRef = thisQuery.find(element => element.value)
        if (attributeRef) {
          if (!acc.includes(attributeRef.value)) {
            return [...acc, attributeRef.value]
          }
        }
        return acc
      }, [])
      filters.forEach(filter => {
        if (uniqueAttributes.find(attribute => areSamePaths(attribute, filter.path))) {
          filter.disabled = true
          filter.value = 'ifPresent'
        } else {
          filter.disabled = false
        }
      })     
      this.filters = filters 
    },
    setSparkQueriesValid(val) {
      this.sparkQueriesValid = val
    },
    forecastComplete(results) {
      this.forecastResults = results
      this.forecastIsStale = false
    },
    costForecastComplete(results) {
      this.costForecastResults = results
      this.costForecastIsStale = false
    },
    makeStepSummary(stepName) {
      return makeStepSummary(stepName, this.stepPayloads, this.filters, this.selectedDestinations )
    },
    forecastUpdating() {
      this.forecastIsStale = true
      this.costForecastIsStale = true
    },
    updateCostSummary(payload) {
      this.costSummary = { ...payload }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.new-page
  padding: 1.5rem
  .header
    display: flex
    justify-content: flex-start
    align-items: flex-start
    position: relative
    margin-bottom: 2rem
    .nio-button
      position: absolute
      right: 0rem
  .nio-forecast-widget
    margin-bottom: 1.5rem
    ::v-deep .v-progress-circular--indeterminate:not(.v-progress-circular--visible) .v-progress-circular__overlay, 
    ::v-deep .v-progress-circular--indeterminate:not(.v-progress-circular--visible)>svg
      animation-play-state: unset !important
  ::v-deep .nio-step-header-slat
    padding-top: 1.375rem
  .nio-divider
    margin-top: -1.25rem
  ::v-deep .v-expansion-panel-content__wrap
    padding: 0rem   
  ::v-deep .nio-step-content
    padding: 0rem 1.5rem 1.5rem 1.5rem
  ::v-deep .nio-step-content-body
    position: relative
    .creating-subscription
      width: 100%
      height: 100%
      position: absolute
      .v-progress-circular
        position: relative
        left: 50%
        top: 6.25rem
        margin-left: -2.5rem
        z-index: 2
  .nio-step-name-attributes, .nio-step-name-budget
    ::v-deep .nio-summary-slat
      padding: 0rem
    ::v-deep .dual-summary
      width: 100%
      width: 40rem
      .entry
        padding: 1rem 1.5rem
        display: flex
        justify-content: space-between
      .entry + .entry
        border-top: 0.0625rem solid $c-primary-lighter
  .nio-step-name-destination ::v-deep .nio-slat-content
    margin-right: 0.92rem
</style>
