import { DefaultOption, IncludeIfPresentOption } from '../../options'


function makeArrayFilter(attribute) {
  return {
    name: attribute.name,
    attributeDescription: attribute.description,
    type: "array",
    title: '',
    customTitle: attribute.path,
    path: attribute.path,
    description: '',
    value: attribute.required ? 'ifPresent' : 'default',
    options: [
      DefaultOption,
      IncludeIfPresentOption
    ]
  }
}

export default makeArrayFilter