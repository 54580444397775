
const DefaultOption = {
  label: `Optional Attribute`,
  value: 'default',
  tooltip: 'Include all rows of data regardless of the value of this attribute.'
}

const IncludeIfPresentOption = {
  label: "Required Attribute",
  value: 'ifPresent',
  tooltip: 'Include only rows of data in which the value of this attribute is not null.'
}

const CustomOption = {
  label: 'Custom Filtering',
  value: 'custom',
  tooltip: 'Include only rows of data in which this attribute’s value(s) fits my indicated criteria.'
}

export {
  DefaultOption,
  IncludeIfPresentOption,
  CustomOption
}