let makeStepSummary = (
  stepName,
  stepPayloads,
  filters,
  selectedDestinations
) => {
  if (!stepPayloads[stepName]) {
    return
  }
  switch (stepName) {
    case 'filters':
      const numFilters = filters?.filter(filter => filter.value !== 'default')?.length || 0
      return {
        title: `${numFilters === 0 ? 'Include all data' : `${numFilters} ${numFilters === 1 ? 'filter' : 'filters'}`}`
      }
    case 'providers':
      let summary
      const payload = stepPayloads.providers
      if (payload.selection === 'default' || payload.numProviders === 0) {
        summary = 'Buy from all sellers'
      } else {
        summary = `Custom: ${payload.listType === 'include' ? 'Include' : 'Exclude'} ${payload.numProviders} seller${payload.numProviders > 1 ? 's' : ''}`
      }
      return {
        title: summary
      }
    case 'destination':
      if (!selectedDestinations) {
        return null
      }
      const numDestinations = selectedDestinations.filter(destination => destination.selected === true).length
      return {
        title: numDestinations > 1 ? `${numDestinations} Destinations` : `Narrative Download` 
      }
    case 'payment':
      return stepPayloads.payment  
    default:
      return {}
  }
}

export default makeStepSummary