import { isValidPositiveNumber } from '@/modules/helpers'

let makeForecastParams = (
  params,
  filtersValid,
  hasDeduplicationError,
  frequencyValid,
  ingestionTimestampValid,
  userRole
) => {
  console.log("SUBSCRIPTION DETAILS:")
  if (isNaN(params.details.pricing?.micro_cents_usd)) {
    params.details.pricing.micro_cents_usd = 1000000
  }
  if (!filtersValid || hasDeduplicationError || !frequencyValid || !ingestionTimestampValid) {
    if (userRole === 99) {
      if (!filtersValid) {
        console.log("Filters are invalid.")
      }
      if (hasDeduplicationError) {
        console.log("Deduplication is invalid.")
      }
      if (!frequencyValid) {
        console.log("Frequency is invalid.")
      }
      if (!ingestionTimestampValid) {
        console.log("Ingestion Timestamp is invalid.")
      }
      if (!isValidPositiveNumber(params.details.pricing?.micro_cents_usd)) {
        console.log("Price cap is invalid.")
      }
    }  
    return null
  } else {
    console.log({details: params.details})
    return params.details
  }     
}

export default makeForecastParams