import { areSamePaths } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'

let selectionsChanged = (current, previous) => {
  const fieldsAdded = current.find(currentAttribute => !previous.find(previousAttribute => areSamePaths(previousAttribute, currentAttribute)))
  const fieldsRemoved = previous.find(previousAttribute => !current.find(currentAttribute => areSamePaths(previousAttribute, currentAttribute)))
  if (current.length !== previous.length || fieldsAdded || fieldsRemoved) {
    return true
  }
  return false
}

export default selectionsChanged