<template lang="pug">
  .payment-step
    NioChoosePaymentMethod(
      @paymentMethodChanged="paymentMethodChanged($event)"
    )
</template>

<script>


export default {
  methods: {
    paymentMethodChanged(payload) {
      this.$emit('stepPayloadChanged', payload)
    }
  }
};
</script>

<style lang="sass" scoped>
  .payment-step
    display: flex
    flex-direction: column
</style>