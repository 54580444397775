<template lang="pug">
  NioFilterGroup.advanced-filters(
    :filters="[...frequency, ...ingestionTimestamp]"
  )
    template(v-slot:filter-properties-custom-frequency="frequency") 
      .title-description
        .filter-title.nio-h4.text-primary-darker Included data points
        .description.nio-p.text-primary-dark Only receive a record that appears 
      v-form.filter-value(
        ref="frequencyForm"
        v-model="frequencyValid"
      )
        .filter-value-row
          .left
            .filter-value-label.nio-p.text-primary-dark At least a minimum of
          .right
            NioTextField(
              v-model="frequency.customOption.value.minOccurences"
              :min="1"
              :rules="[validateMin]"
              type="number"
              step="1"
              solo
            )
            .filter-value-label.nio-p.text-primary-dark times
        .filter-value-row
          .left
            .filter-value-label.nio-p.text-primary-dark Up to a maximum of
          .right
            NioTextField(
              v-model="frequency.customOption.value.maxOccurences"
              :rules="[validateMax]"
              type="number"
              step="1"
              solo
            )
            .filter-value-label.nio-p.text-primary-dark times
        .filter-value-row.data-points
          .left
            .filter-value-label.nio-p.text-primary-dark For this data point:
          .right
            NioSelect(
              v-model="frequency.customOption.value.dataPoints"
              :items="frequency.customOption.config.dataPointsOptions"
              :rules="[rules.required]"
              label="Choose a data point"
              item-text="label"
              item-value="value"
              validate-on-blur
              multiple
            )
    .rescan-data
      .title-description
        .filter-title.nio-h4.text-primary-darker Re-scan Data
        .description.nio-p.text-primary-dark Rescan data to check for new matches on your joined dataset. Buyers should only rescan data if they plan on appending data to their input dataset.
      .filter-value 
        NioSwitch(
          v-model="localRescanData"
          label="Re-scan"
          @update="$emit('rescanDataChanged', $event)"
        ) Rescan data
    Constraints(
      :spark="spark"
      :dataRulesParams="dataRulesParams"
      :filterable-fields="filterableFields"
      @disableFiltersForSparkQueries="$emit('disableFiltersForSparkQueries')"
      @setSparkQueriesValid="$emit('setSparkQueriesValid', $event)"
      @checkingSparkQueries="$emit('checkingSparkQueries')"
      @checkingSparkQueriesComplete="$emit('checkingSparkQueriesComplete')"
    )
    .spark-message
      .nio-p.text-primary-dark(v-if="checkingSparkQueries") Validating Spark SQL Queries
</template>

<script>

import { getThemeColor } from '@narrative.io/tackle-box/src/modules/app/theme/theme'
import { validateFrequencyFilterMin, validateFrequencyFilterMax, validateFrequencyFilterPeriod } from '@/modules/filters/types/advanced/frequency'
import Constraints from './Constraints'

export default { 
  components: { Constraints },
  props: {
    "frequency": { type: Array, required: false, default: null },
    "ingestionTimestamp": { type: Array, required: false, default: null },
    "rescanData": { type: Boolean, required: false, default: false },
    "spark": { type: Object, required: true },
    "sparkQueriesValid": { type: Boolean, required: false, default: false },
    "checkingSparkQueries": { type: Boolean, required: false, default: false },
    "filterableFields": { type: Array, required: false },
    "dataRulesParams": { type: Object, required: false }
  },
  data: () => ({
    rules: {
      required: function(value) { 
        if (!value || !value.length > 0) {
          return 'Required'
        } else {
          return true
        }
      }
    },
    frequencyValid: true,
    localRescanData: false
  }),
  watch: {
    frequency: {
      deep: true,
      handler() {
        if (this.$refs.frequencyForm) {
          this.$refs.frequencyForm.validate()
        }
      }
    }
  },
  methods: {
    validateMin(value) {
      return validateFrequencyFilterMin(value, this.frequency[0])
    },
    validateMax(value) {
      return validateFrequencyFilterMax(value, this.frequency[0])
    },
    validatePeriod(value) {
      return validateFrequencyFilterPeriod(value)
    },
    getThemeColor(colorName) {
      return getThemeColor(colorName)
    },
    rescanDataChanged(val) {
      this.$emit('rescanDataChanged', val)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.advanced-filters
  position: relative
  .spark-message
    position: absolute
    bottom: 2.625rem
    left: 2rem
  .title-description
    margin-bottom: 1rem
    .nio-h4
      margin-bottom: 0.25rem
  .filter-value   
    .filter-value-row
      display: flex
      align-items: center
      ::v-deep .v-text-field__details
        bottom: -1.7rem
        .v-messages__message
          white-space: nowrap
      .left
        width: 33.33%
        flex-grow: 0
        flex-shrink: 0
        display: flex
        justify-content: flex-end
        height: 3.375rem
        align-items: center
        padding-right: 1rem
      .right
        width: 66.66%
        flex-grow: 0
        display: flex
        justify-content: flex-start
        height: 3.375rem
        align-items: center
        & > * + *
          margin-left: 1rem
        .nio-text-field
          width: 5rem
          flex-grow: 0   
      .nio-text-field, .nio-select
        margin-bottom: 0rem
        ::v-deep .v-input__slot
          border-width: 0.0625rem !important
      &.data-points
        .nio-select
          max-width: 18.4375rem
      &.period
        .nio-select
          max-width: 12.25rem
    & > * + *
      margin-top: 1.5rem
  ::v-deep .simple-timestamp
    .instructions
      display: none
    .heading-description
      .description
        display: none
  .rescan-data
    +nio-filter-header  
    ::v-deep .nio-switch
      margin-top: 0rem
  
</style>
