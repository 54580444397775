<template lang="pug">
  .filters-step
    .header.step-header
      h2.nio-h2.text-primary-darker Add filters
      p.nio-p.text-primary-dark Using default values will gather as much data as possible. Add filters to narrow your data.
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .data-package-price
      SchemaPresetSummary(
        v-if="schemaPreset"
        :schema-preset="schemaPreset"
      )
      .price-cap
        .title-description
          .filter-title.nio-h4.text-primary-darker Price Ceiling
          .description.nio-p.text-primary-dark Enter the maximum amount you're willing to spend per 1,000 events.
        .filter-value
          NioTextField(
            ref="price-cap"
            v-model="localPriceCap"
            :rules="[rules.required]"
            type="number"
            prepend
            currency
            validate-on-blue
            @update="updatePriceCap($event)"
            @blur="priceCapBlur"
          )
          .description.nio-p.normal.text-primary-dark If the price ceiling is greater than the seller's price, you will only be charged the lower amount.
    NioFilterGroup(
      v-if="filters"
      :filters="filters"
    )
      template(v-slot:filter-header-name-custom="slotProps")
        NioPrettySchemaPath(
          :path="slotProps.filter.customTitle"
          display-only
        )
        .attribute-description.nio-p.text-primary-dark {{ slotProps.filter.attributeDescription }}
    p.nio-p.text-primary-dark.text-center(v-if="!hasFilterable") No Attribute Filters Selected
    NioFilterGroup(
      v-if="deduplication && hasFilterable"
      :filters="deduplication"
    )
    .advanced-filter-controls
      NioButton.advanced-filters-button(
        v-if="!showAdvancedFilters && hasFilterable"
        normal-secondary
        @click="showAdvancedFilters = true"
      ) Show Advanced Filters
      NioButton.advanced-filters-button(
        v-if="showAdvancedFilters"
        normal-secondary
        @click="showAdvancedFilters = false"
      ) Close Advanced Filters
    AdvancedFilters(
      v-if="showAdvancedFilters && hasFilterable"
      :frequency="frequency"
      :ingestion-timestamp="ingestionTimestamp"
      :rescan-data="rescanData"
      :spark="spark"
      :spark-queries-valid="sparkQueriesValid"
      :checking-spark-queries="checkingSparkQueries"
      :filterable-fields="filterableFieldsItems"
      :data-rules-params="dataRulesParams"
      @rescanDataChanged="rescanDataChanged($event)"
      @disableFiltersForSparkQueries="$emit('disableFiltersForSparkQueries')"
      @setSparkQueriesValid="$emit('setSparkQueriesValid', $event)"
      @checkingSparkQueries="$emit('checkingSparkQueries')"
      @checkingSparkQueriesComplete="$emit('checkingSparkQueriesComplete')"
    )
    .invalid-error(v-if="generalErrorMsg")
      .nio-p.text-error Invalid filter: <span class="nio-bold">{{ generalErrorMsg }}</span>
    .invalid-error(v-else-if="deduplication && deduplication[0].value === 'custom' && hasFilterable")
      .nio-p.text-error(v-if="!deduplicationPeriodValid && hasFilterable") Deduplication frequency must be a positive number <= 60
      .nio-p.text-error(v-else-if="!deduplicationFormatValid && hasFilterable") Deduplication frequency must be a whole number
      .nio-p.text-error(v-else-if="!deduplicationPathsValid && hasFilterable") Deduplication must specify at least one data point
</template>

<script>

import SchemaPresetSummary from '@/shared/components/SchemaPresetSummary'
import { formatFloat, isValidPositiveNumber } from '@/modules/helpers'
import AdvancedFilters from './AdvancedFilters'

export default { 
  components: { SchemaPresetSummary, AdvancedFilters },
  props: {
    "loading": { type: Boolean, required: false, default: false},
    "schemaPreset": { type: Object, required: false, default: null},
    "filters": { type: Array, required: false, default: null},
    "priceCap": { type: Number, required: true }, 
    "priceCapValid": { type: Boolean, required: true }, 
    "deduplication": { type: Array, required: false, default: null},
    "frequency": { type: Array, required: false, default: null },
    "ingestionTimestamp": { type: Array, required: false, default: null },
    "rescanData": { type: Boolean, required: false, default: false },
    "spark": { type: Object, required: true },
    "deduplicationPeriodValid": { type: Boolean, required: false, default: false },
    "deduplicationFormatValid": { type: Boolean, required: false, default: false },
    "deduplicationPathsValid": { type: Boolean, required: false, default: false },
    "frequencyValid": { type: Boolean, required: false, default: false},
    "ingestionTimestampValid": { type: Boolean, required: false, default: false},
    "sparkQueriesValid": { type: Boolean, required: false, default: false },
    "checkingSparkQueries": { type: Boolean, required: false, default: false },
    "filterableFieldsItems": { type: Array, required: false },
    "filtersPayload": { type: Object, required: false },
    "hasFilterable": {type: Boolean, required: false, default: true},
    "dataRulesParams": { type: Object, required: false, default: null }
  },
  data: () => ({
    generalErrorMsg: null,
    rules: {
      required: function(value) { return !!value || 'Required' }
    },
    showAdvancedFilters: false,
    filterableFields: [],
    localPriceCap: '1.00'
  }),
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.makeGeneralErrorMsg()
      }
    },
    frequencyValid() {
      this.makeGeneralErrorMsg()
    },
    sparkQueriesValid() {
      this.makeGeneralErrorMsg()
    },
    ingestionTimestampValid() {
      this.makeGeneralErrorMsg()
    },
    priceCap(val) {
      if (val !== parseFloat(this.localPriceCap)) {
        this.localPriceCap = String(val.toFixed(2))
      }
      this.makeGeneralErrorMsg()
    }
  },
  mounted() {
    this.updatePriceCap(1.00)
  },
  methods: {
    updatePriceCap(val) {
      this.$emit('stepPayloadChanged', {
        priceCap: this.localPriceCap === '' ? null : parseFloat(this.localPriceCap),
        filters: this.filters,
        deduplication: this.deduplication,
        rescanData: this.rescanData,
      })
    },
    rescanDataChanged(val) {
      this.$emit('stepPayloadChanged', {
        priceCap: this.localPriceCap === '' ? null : parseFloat(this.localPriceCap),
        filters: this.filters,
        deduplication: this.deduplication,
        rescanData: val,
      })
    },
    priceCapBlur() {
      if (this.localPriceCap) {
        this.localPriceCap = formatFloat(this.localPriceCap)
      }
    },
    makeGeneralErrorMsg() {
      if(!this.hasFilterable){
        return 
      }
      const firstInvalidFilter = this.filters.find(filter => filter.value === 'custom' && filter.valid === false)
      if (!this.priceCapValid) {
        this.generalErrorMsg = 'Price cap is invalid'
      } else if (firstInvalidFilter) {
        this.generalErrorMsg = firstInvalidFilter.path.reduce((acc, pathElement) => {
          if (!pathElement.length) {
            return acc + `${pathElement.displayName}`
          } else {
            return acc + ` > ${pathElement}`
          }
        }, '')
      } else if (this.filters.filter(filter => filter.value === 'join').length > 1) {
        this.generalErrorMsg = 'Only one filter may have the Join Dataset option selected'
      } else if (!this.filters.find(filter => filter.value !== "default" )) {
        this.generalErrorMsg = "At least one filter must have a value other than Optional 'Attribute'"
      } else if (!this.sparkQueriesValid) {
        this.generalErrorMsg = "One or more Spark SQL queries are invalid"
      } else if (!this.frequencyValid) {
        this.generalErrorMsg = "Frequency filter is invalid"
      } else if (!this.ingestionTimestampValid) {
        this.generalErrorMsg = "Ingestion Timestamp filter is invalid"
      } else {
        this.generalErrorMsg = undefined
      }
    }
  }
}
</script>


<style lang="sass">
  .nio-select-menu.spark-field-select
    .nio-pretty-schema-path        
      .path-element
        .nio-p
          white-space: nowrap    
      .nio-button-append
        display: none
</style>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter"
@import "@narrative.io/tackle-box/src/styles/mixins/filter/_filter-header"

.filters-step
  .data-package-price
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
    .price-cap
      +nio-filter-header
      .filter-value
        display: flex
        flex-direction: column
        .nio-slider
          width: 100%
        .description
          margin-top: 1rem
  .pretty-attribute-path
    flex-wrap: wrap
    margin-right: 1.5rem
    .nio-icon
      margin-top: 0.125rem
  .pretty-attribute-path .path-element, .pretty-attribute-path .path-element .nio-p
    color: $c-primary-darker
    font-size: 1rem
    line-height: 1.25rem
    letter-spacing: 0.0125rem
    font-family: 'Euclid Circular A'
    font-weight: 600
  .attribute-description
    padding-left: 0.5rem
    padding-right: 1rem
  .max-apply-message
    bottom: -1.25rem !important
    font-size: 0.625rem !important
    color: $c-primary-dark
  .nio-filter-group
    margin-bottom: 1.5rem
  .deduplication
    border-radius: 0.75rem
    border: 0.0625rem solid $c-primary-lighter
    overflow: hidden
    margin: 0rem 0rem 1.5rem 0rem
    .filter-content 
      +nio-filter-header
  .deduplication-error
    position: absolute
    bottom: 3.125rem
  .invalid-error
    position: absolute
    bottom: 3.125rem
  .advanced-filter-controls
    display: flex
    justify-content: flex-end
    margin: 1.5rem 0rem
  .advanced-filters
    position: relative
    .spark-message
      position: absolute
      bottom: 2.625rem
      left: 2rem
    .title-description
      margin-bottom: 1rem
      .nio-h4
        margin-bottom: 0.25rem
    .filter-value   
      .filter-value-row
        display: flex
        align-items: center
        .left
          width: 33.33%
          flex-grow: 0
          flex-shrink: 0
          display: flex
          justify-content: flex-end
          height: 3.375rem
          align-items: center
          padding-right: 1rem
        .right
          width: 66.66%
          flex-grow: 0
          display: flex
          justify-content: flex-start
          height: 3.375rem
          align-items: center
          & > * + *
            margin-left: 1rem
          .nio-text-field
            width: 5rem
            flex-grow: 0   
        .nio-text-field, .nio-select
          margin-bottom: 0rem
          ::v-deep .v-input__slot
            border-width: 0.0625rem !important
        &.data-points
          .nio-select
            max-width: 18.4375rem
        &.period
          .nio-select
            max-width: 12.25rem
      & > * + *
        margin-top: 1rem
    .constraints
      width: 100%
      +nio-filter-header
      flex-direction: column
      .filter-value
        width: 100%
        .add-spark-query
          align-self: flex-end
        .spark-query
          width: 100%
          display: flex
          justify-content: space-around
          & > * + *
            margin-left: 0.5rem
          .nio-select
            max-width: 21.25rem
            ::v-deep .v-select__selections 
              margin-left: 0.55rem
              overflow: hidden
            ::v-deep .nio-pretty-schema-path        
              .path-element
                white-space: nowrap
                .nio-p
                  white-space: nowrap    
              .nio-button-append
                display: none
          .nio-text-field
            flex-grow: 2
            ::v-deep .v-input__slot
              border-width: 0.0625rem !important
          .remove-spark-query
            width: 100%
            height: 3.375rem
            width: 3.375rem
            flex-shrink: 0
            flex-grow: 0
            border: 0.0625rem solid $c-primary-lighter
            border-radius: 0.5rem
            &:hover
              cursor: pointer
              background-color: $c-canvas 
       
  // removing until supported in the backend
  .join-option
    .join-type, .nio-divider
      display: none
  
</style>
